export const occupationList = [
  { id: "1", name: "営業" },
  { id: "2", name: "マーケティング" },
  { id: "3", name: "デザイン" },
  { id: "4", name: "音楽" },
  { id: "5", name: "インフルエンサー" },
  { id: "6", name: "R＆D/企画" },
  { id: "7", name: "経営企画" },
  { id: "8", name: "開発" },
  { id: "9", name: "情報システム" },
  { id: "10", name: "カスタマーサポート" },
  { id: "11", name: "人事" },
  { id: "12", name: "法務" },
  { id: "13", name: "財務/会計/経理" },
  { id: "14", name: "事務/アシスタント" },
  { id: "15", name: "その他" },
] as const;
