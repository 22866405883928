import Lottie, { type LottieComponentProps } from "lottie-react";
import { useEffect, useRef, useState } from "react";
type Props = LottieComponentProps;
export const ScrollTriggerLottie = (props: Props) => {
  const lottieRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0,
      },
    );

    if (lottieRef.current) {
      observer.observe(lottieRef.current);
    }

    return () => {
      if (observer?.unobserve && lottieRef.current) {
        observer.unobserve(lottieRef.current);
      }
    };
  });

  return <div ref={lottieRef}>{isVisible && <Lottie {...props} />}</div>;
};
