import type { EmblaOptionsType } from "embla-carousel";
import profileImage1 from "../assets/interview/1.png";
import profileImage2 from "../assets/interview/2.png";
import profileImage3 from "../assets/interview/3.png";
import profileImage4 from "../assets/interview/4.png";
import { EmblaCarousel } from "./slide/embla-carousel";

const OPTIONS: EmblaOptionsType = { containScroll: false, loop: true };

const SLIDES = [
  {
    color: "#C4B4DC",
    message:
      "デスクトップで汎用性の高いAIスキルをいつでも使えるのが非常に便利です。最近、大学の授業でプレゼン資料を作成しました。sattoのスキルでMarpを生成し、ファイル形式をパワーポイントに変換。最終的に、そのパワーポイントを使って授業で発表し、とても効果的でした。",
    userName: "木村 太陽さん",
    job: "学生エンジニア",
    userImage: profileImage1,
  },
  {
    color: "#3C82C8",
    message:
      "ビジネスシーンでよく相談されるのが比較です。比較するためのプロセス（検索してまとめて比較資料として提出）は意外と大変のため、この処理をsatto にまかせることで時間短縮できます。最近の利用シーンではPCスペック比較やお昼のランチ比較です。",
    userName: "野村 龍吾 さん",
    job: "経営者",
    userImage: profileImage2,
  },
  {
    color: "#B4DCF0",
    message:
      "今まで自動化のために組んでいたプログラムを、グラフィックで開発できることにワクワクしています！きたるAGI実現の前に、sattoでワークフローの感覚を身に着けましょう！お気に入りスキル：「宛名作成」(メール+AI+スプレッドシート)",
    userName: "ユウスケ さん",
    job: "Webエンジニア",
    userImage: profileImage3,
  },
  {
    color: "#FFAAB2",
    message:
      "FlutterFlow というノーコード開発との相性が良いと思い使わせてもらっています。コーディングが必要な時にこれまではAIとの対話で解決しようとしていましたが、事前にプロンプトを読み込ませた自分専用のスキルを使うことによってコーディングの一部を手伝ってもらっています。",
    userName: "皿井 宏幸 さん",
    job: "エンジニア",
    userImage: profileImage4,
  },
];

export const InterviewSection = () => {
  return (
    <div>
      <EmblaCarousel slides={SLIDES} options={OPTIONS} />
    </div>
  );
};
