import { industryList } from "@acme/common/src/constants/industry";
import { occupationList } from "@acme/common/src/constants/occupation";
import { IconGoogle } from "@acme/ui/src/components/atoms/icon/app";

import { getFormProps, getTextareaProps, useForm } from "@conform-to/react";
import { parseWithZod } from "@conform-to/zod";
import type {} from "@remix-run/node";
import { Form, useSearchParams } from "@remix-run/react";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { z } from "zod";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import { TextLink } from "~/components/ui/text-link";
import { Textarea } from "~/components/ui/textarea";
import { cn } from "~/lib/utils";
import { useWaitlistStore } from "~/stores/waitlist";
import { BgCoCreator, BgCoCreatorSp } from "./bg-co-creator";

export const schema = z.object({
  occupationId: z.string({ required_error: "職種を選択してください" }),
  industryId: z.string({ required_error: "業種を選択してください" }),
  message: z.string().optional(),
  // isValid: z.boolean().refine((v) => v, "同意してください"),
});

export const WaitlistSection = () => {
  const { setWaitlist } = useWaitlistStore();
  const [searchParams] = useSearchParams();

  const [form, fields] = useForm({
    onValidate: ({ formData }) => parseWithZod(formData, { schema }),
    shouldValidate: "onSubmit",
    shouldRevalidate: "onInput",
    onSubmit(_, context) {
      if (context.submission?.payload) {
        setWaitlist({
          industryId: context.submission.payload.industryId as string,
          occupationId: context.submission.payload.occupationId as string,
          message: context.submission.payload.message as string,
          utm_source: searchParams.get("utm_source") as string,
          utm_medium: searchParams.get("utm_medium") as string,
          utm_campaign: searchParams.get("utm_campaign") as string,
          utm_content: searchParams.get("utm_content") as string,
        });
      }
    },
  });

  const sectionObserve = () => {
    const contents = document.getElementById("waitlist-contents");
    if (!contents) {
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      for (const e of entries) {
        if (e.isIntersecting) {
          if (window.matchMedia("(min-width: 768px)").matches) {
            contents.classList.add("!bg-[0px_175px]");
          } else {
            contents.classList.add("!bg-[0px_99px]");
          }
        } else {
          if (window.matchMedia("(min-width: 768px)").matches) {
            contents.classList.remove("!bg-[0px_175px]");
          } else {
            contents.classList.remove("!bg-[0px_99px]");
          }
        }
      }
    });
    observer.observe(contents);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    sectionObserve();
  }, []);
  return (
    <div
      id="waitlist-contents"
      className={cn(
        "relative w-screen overflow-hidden bg-[100%_99px] bg-no-repeat md:bg-[100%_175px]",
        // `bg-[position:7px_0] bg-[url('/app/routes/_home+/_index/assets/bg-sp.png')]`,
        // `md:bg-[position:0_-80px] md:bg-[url('/app/routes/_home+/_index/assets/bg.png')]`,
      )}
      style={{
        // backgroundPositionY: "-80px",
        // backgroundPositionX: "0px",
        backgroundImage:
          "linear-gradient(90deg, #F3F3F4, #F3F3F4 50%, #fafafa 50%, #fafafa)",
        backgroundSize: "200% 100%",
        transition:
          "background-position 0.4s cubic-bezier(0.25, 1, 0.5, 1) 1.3s",
      }}
    >
      <svg
        width="2806"
        height="309"
        viewBox="0 0 2806 309"
        fill="none"
        className="relative left-[-22px] hidden md:block"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M1704.99 28.0908C1567.5 17.0149 1291.5 39.3275 1228.8 39.3335C1224.54 39.323 1212.44 39.9068 1216.45 40.3821C1257.8 45.2791 1303.3 48.1678 1345.58 51.7992C1506.94 65.656 1667.98 79.7599 1825.53 97.8398C1902.39 106.66 1978.19 116.536 2054.33 125.996C2066.72 127.536 2103.4 131.667 2090.17 131.634C2032.55 131.492 1972.62 125.658 1916.4 122.419C1719.69 111.088 1521.82 101.816 1323.88 93.2113C1207.09 88.135 1090.33 84.3897 973.003 81.1702C951.299 80.5747 929.463 80.3495 907.937 79.2464M907.937 79.2464C905.486 79.1208 903.039 78.9838 900.596 78.8336C893.73 78.4113 898.76 78.6775 907.937 79.2464ZM907.937 79.2464C920.024 79.9957 939.305 81.27 948.072 82.1878C1098.57 97.9436 1248.84 113.141 1401.43 126.698C1680.91 151.53 1962.03 174.862 2237.9 203.831C2400.15 220.869 2569.15 239.478 2714.95 268.979C2731.78 272.385 2748.24 275.968 2765.26 279.277C2766.06 279.433 2783.42 282.341 2780.44 282.859C2772.02 284.329 2719.94 278.395 2712.08 277.912C2546.88 267.77 2381.53 257.919 2215.95 248.48C1837.23 226.891 1338.66 172.952 957.891 155.598C797.654 148.295 705.553 146.956 546.547 137.454C428.237 130.383 324.082 136.975 206.101 129.419C135.432 124.893 97.6028 125.332 25.3635 125.154"
          stroke="#F3F3F4"
          strokeWidth="50"
          strokeLinecap="round"
          variants={{
            offscreen: {
              pathLength: 0,
            },
            onscreen: {
              pathLength: 1,
            },
          }}
          initial="offscreen" // 初期表示はoffscreen
          whileInView="onscreen" // 画面内に入ったらonscreen
          transition={{
            ease: "easeInOut",
            duration: 1.3,
          }}
        />
      </svg>
      <svg
        width="369"
        height="129"
        viewBox="0 0 369 129"
        fill="none"
        className="w-full md:hidden"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M280.754 13.8992C267.939 9.84146 214.098 29.4998 205.732 29.9745C205.038 30.0139 203.088 30.3826 203.756 30.5403C210.639 32.1656 218.134 32.9075 225.13 33.9932C251.826 38.1364 278.478 42.3863 304.687 48.3365C317.473 51.2393 330.121 54.595 342.811 57.773C344.876 58.2902 350.976 59.6425 348.821 59.7649C339.44 60.2972 329.504 58.4704 320.252 57.6922C287.883 54.9696 255.392 53.1203 222.908 51.5516C203.744 50.6261 184.625 50.2576 165.431 50.115C161.476 50.0857 157.509 50.2503 153.574 49.8806C150.954 49.6345 158.845 50.2796 161.406 50.7971C186.394 55.8468 211.329 60.6649 236.588 64.7729C282.853 72.297 329.337 79.1765 375.146 88.4696C402.088 93.9352 430.178 99.9892 454.837 110.841C457.684 112.094 460.475 113.425 463.35 114.635C463.485 114.692 466.401 115.731 465.934 115.979C464.61 116.681 455.947 114.731 454.653 114.61C427.449 112.061 400.231 109.636 372.988 107.385C310.676 102.238 227.837 84.7797 165.327 81.4262C139.021 80.015 123.992 80.4006 97.8162 78.0559C78.3402 76.3113 61.6001 80.1408 42.1621 78.1896C30.5192 77.0209 24.3772 77.5932 12.6163 78.2606"
          stroke="#F3F3F4"
          strokeWidth="25"
          strokeLinecap="round"
          variants={{
            offscreen: {
              pathLength: 0,
            },
            onscreen: {
              pathLength: 1,
            },
          }}
          initial="offscreen" // 初期表示はoffscreen
          whileInView="onscreen" // 画面内に入ったらonscreen
          transition={{
            ease: "easeInOut",
            duration: 1.3,
          }}
        />
      </svg>

      <div className="mt-[-62px] h-full w-full md:mt-[-188px]">
        <div>
          <div className="relative mx-auto max-w-[600px] px-5">
            <div className="-translate-x-1/2 absolute top-[-35px] left-1/2 hidden md:block">
              <div className="hidden md:block">
                <BgCoCreator />
              </div>
            </div>
            <div className="-translate-x-1/2 absolute top-[-66px] left-1/2 block md:hidden">
              <BgCoCreatorSp />
            </div>
            <p className="mb-10 text-center font-semibold">
              <span
                className={cn(
                  "font-medium text-[20px] text-black leading-[130%]",
                  "md:text-[27px]",
                )}
              >
                sattoを一緒に育てる
              </span>
              <span
                className={cn(
                  "block font-medium text-[27px] text-black leading-[130%]",
                  "md:text-[54px]",
                )}
              >
                先行ユーザー募集
              </span>
            </p>
            <p className="mb-7 text-left font-medium text-[14px] text-black leading-[180%] md:text-center md:text-[18px]">
              私たちと一緒に、新しいアイデアや工夫を共有し、
              <br className="hidden md:inline" />
              仕事をサッと片付ける毎日を実現していきましょう。
            </p>
          </div>
          <div className="mx-auto max-w-[600px] px-5 md:px-0">
            <div className="flex flex-col justify-center gap-[10px] rounded-[12px] bg-white px-[22px] py-[23px]">
              <p
                className={cn(
                  "text-center font-medium text-[14px] text-black",
                  "text-[18px]",
                )}
              >
                募集詳細
              </p>
              <ol
                className={cn(
                  "ml-[1em] list-decimal text-[13px] text-black leading-[180%]",
                  "md:text-[14px]",
                )}
              >
                <li>
                  本サービスはmacOSを使用し、Googleアカウントをお持ちの方のみご利用可能です。（Windowsは今後対応予定）
                </li>
                <li>
                  ベータ版にご登録いただいた方の中から2024年9月以降順次ご案内メールをお送りいたします。（ご登録いただいた方全員が必ずしも先行利用できるとは限りません。）
                </li>
                <li>
                  ウェイトリスト登録は、１つのGoogle
                  アカウントにつき１回までになります。
                </li>
              </ol>
            </div>
          </div>
          <Form
            {...getFormProps(form)}
            method="post"
            className="w-full pb-[60px]"
          >
            <div className="mx-auto max-w-[600px] px-5 md:px-0">
              <div>
                <div>
                  <div className="pt-4 pr-2 pb-2">
                    <Label
                      htmlFor={fields.industryId.id}
                      className={cn(
                        "font-medium text-[#000] text-[14px]",
                        "md:text-[18px]",
                      )}
                    >
                      あなたの業界
                    </Label>
                  </div>
                  <Select
                    key={fields.industryId.key}
                    name={fields.industryId.name}
                    defaultValue={fields.industryId.initialValue}
                    onValueChange={(value) => {
                      form.update({
                        name: fields.industryId.name,
                        value,
                      });
                    }}
                  >
                    <SelectTrigger
                      id={fields.industryId.id}
                      aria-invalid={!fields.industryId.valid || undefined}
                      aria-describedby={
                        !fields.industryId.valid
                          ? fields.industryId.errorId
                          : undefined
                      }
                      className={cn(
                        "h-[44px] border-[rgba(22,22,21,0.15)] bg-white font-poppins text-[13px] text-[rgba(22,22,21,0.40)] leading-[24px] shadow-none focus:border-inherit",
                        fields.industryId.value && "text-black",
                      )}
                    >
                      <SelectValue placeholder="選択してください" />
                    </SelectTrigger>
                    <SelectContent className="shadow-none">
                      {industryList.map((industry) => (
                        <SelectItem key={industry.id} value={industry.id}>
                          {industry.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {fields.industryId.errors?.length && (
                    <p className="font-medium text-[14px] text-red-500">
                      {fields.industryId.errors[0]}
                    </p>
                  )}
                </div>
                <div>
                  <div className="pt-4 pr-2 pb-2">
                    <Label
                      htmlFor={fields.occupationId.id}
                      className={cn(
                        "font-medium text-[#000] text-[14px]",
                        "md:text-[18px]",
                      )}
                    >
                      あなたの職種
                    </Label>
                  </div>
                  <Select
                    key={fields.occupationId.key}
                    name={fields.occupationId.name}
                    defaultValue={fields.occupationId.initialValue}
                    onValueChange={(value) => {
                      form.update({
                        name: fields.occupationId.name,
                        value,
                      });
                    }}
                  >
                    <SelectTrigger
                      id={fields.occupationId.id}
                      aria-invalid={!fields.occupationId.valid || undefined}
                      aria-describedby={
                        !fields.occupationId.valid
                          ? fields.occupationId.errorId
                          : undefined
                      }
                      className={cn(
                        "h-[44px] border-[rgba(22,22,21,0.15)] bg-white font-poppins text-[13px] text-[rgba(22,22,21,0.40)] leading-[24px] shadow-none focus:border-inherit",
                        fields.occupationId.value && "text-black",
                      )}
                    >
                      <SelectValue placeholder="選択してください" />
                    </SelectTrigger>
                    <SelectContent className="shadow-none">
                      {occupationList.map((job) => (
                        <SelectItem key={job.id} value={job.id}>
                          {job.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {fields.occupationId.errors?.length && (
                    <p className="font-medium text-[14px] text-red-500">
                      {fields.occupationId.errors[0]}
                    </p>
                  )}
                </div>
                <div>
                  <div className="pt-4 pr-2 pb-2">
                    <Label
                      htmlFor={fields.message.id}
                      className={cn(
                        "font-medium text-[#000] text-[14px]",
                        "md:text-[18px]",
                      )}
                    >
                      sattoへのメッセージ(任意)
                    </Label>
                  </div>
                  <Textarea
                    placeholder="メッセージを入力してください"
                    {...getTextareaProps(fields.message)}
                    className="h-[117px] resize-none border-[rgba(22,22,21,0.15)] bg-white text-[13px] leading-[20px] shadow-none placeholder:text-[rgba(22,22,21,0.40)]"
                  />
                </div>
                <div className="mt-[25px] flex flex-col items-center gap-4 md:mt-10 md:gap-6">
                  <div className="mx-auto flex items-center gap-2">
                    {/* <Checkbox
                key={fields.isValid.key}
                id={fields.isValid.id}
                name={fields.isValid.name}
                required={fields.isValid.required}
                defaultChecked={fields.isValid.initialValue === "on"}
                aria-invalid={!fields.isValid.valid || undefined}
                aria-describedby={
                  !fields.isValid.valid ? fields.isValid.errorId : undefined
                }
                onCheckedChange={(checked) => {
                  form.update({
                    name: fields.isValid.name,
                    value: checked,
                  });
                }}
                className={cn(
                  "border border-input bg-white",
                  fields.isValid.valid && "border-success",
                  !fields.isValid.valid && "border-destructive",
                )}
              /> */}
                    <p
                      className={cn(
                        " text-[#1A1A1A] text-[12px]",
                        "md:text-[14px]",
                      )}
                    >
                      <TextLink href="/privacypolicy" target="_blank">
                        プライバシーポリシー
                      </TextLink>
                      に同意した上で登録してください
                    </p>
                  </div>

                  <div className="relative">
                    <svg
                      width="579"
                      height="94"
                      viewBox="0 0 579 94"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={cn(
                        "-translate-x-1/2 absolute left-1/2 hidden",
                        "md:block",
                      )}
                    >
                      <motion.path
                        d="M47.5852 41.0378C48.2296 39.2965 50.6923 39.2965 51.3366 41.0378L53.4021 46.6195C54.4149 49.3567 56.573 51.5148 59.3102 52.5276L64.8919 54.593C66.6332 55.2374 66.6332 57.7001 64.8919 58.3445L59.3102 60.4099C56.573 61.4227 54.4149 63.5808 53.4021 66.318L51.3366 71.8997C50.6923 73.641 48.2295 73.641 47.5852 71.8997L45.5198 66.318C44.507 63.5808 42.3489 61.4227 39.6117 60.4099L34.0299 58.3445C32.2887 57.7001 32.2887 55.2374 34.0299 54.593L39.6117 52.5276C42.3489 51.5148 44.507 49.3567 45.5198 46.6195L47.5852 41.0378Z"
                        fill="#FFF46E"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                          delay: 0.2,
                        }}
                      />
                      <motion.path
                        d="M13.7804 19.0426C13.8921 18.121 15.09 17.8358 15.605 18.6081L17.8158 21.9229C19.4352 24.351 22.0345 25.9504 24.9319 26.3016L28.8873 26.7811C29.8089 26.8928 30.0941 28.0907 29.3218 28.6058L26.007 30.8166C23.5789 32.4359 21.9795 35.0353 21.6282 37.9326L21.1488 41.8881C21.0371 42.8096 19.8392 43.0949 19.3241 42.3226L17.1133 39.0078C15.494 36.5797 12.8946 34.9802 9.99724 34.629L6.04181 34.1495C5.12025 34.0378 4.83499 32.8399 5.6073 32.3249L8.9221 30.1141C11.3502 28.4947 12.9497 25.8954 13.3009 22.998L13.7804 19.0426Z"
                        fill="white"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                        }}
                      />
                      <motion.path
                        d="M555.378 7.12017C555.973 6.40751 557.134 6.81949 557.146 7.74772L557.205 12.1837C557.244 15.102 558.555 17.8578 560.796 19.7281L564.202 22.5709C564.914 23.1658 564.502 24.3262 563.574 24.3385L559.138 24.3974C556.22 24.4361 553.464 25.7478 551.594 27.9884L548.751 31.3942C548.156 32.1069 546.996 31.6949 546.983 30.7667L546.924 26.3307C546.886 23.4124 545.574 20.6566 543.333 18.7863L539.928 15.9435C539.215 15.3486 539.627 14.1882 540.555 14.1759L544.991 14.117C547.909 14.0783 550.665 12.7666 552.535 10.526L555.378 7.12017Z"
                        fill="#FFF46E"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                          delay: 0.6,
                        }}
                      />
                      <motion.path
                        d="M556.882 54.6712C558.072 53.2458 560.392 54.0698 560.417 55.9263L560.483 60.9123C560.522 63.8306 561.834 66.5864 564.074 68.4566L567.902 71.652C569.328 72.8418 568.504 75.1626 566.647 75.1873L561.661 75.2534C558.743 75.2921 555.987 76.6038 554.117 78.8444L550.921 82.6725C549.732 84.0979 547.411 83.2739 547.386 81.4174L547.32 76.4314C547.281 73.5131 545.97 70.7573 543.729 68.8871L539.901 65.6917C538.476 64.5019 539.3 62.1811 541.156 62.1565L546.142 62.0903C549.06 62.0516 551.816 60.7399 553.686 58.4993L556.882 54.6712Z"
                        fill="white"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                          delay: 0.8,
                        }}
                      />
                      <motion.path
                        d="M518.954 18.5235C520.144 17.0982 522.465 17.9222 522.489 19.7786L522.526 22.5127C522.642 31.2677 526.577 39.535 533.299 45.1458L535.398 46.898C536.823 48.0877 535.999 50.4086 534.143 50.4332L531.409 50.4695C522.654 50.5856 514.387 54.5207 508.776 61.2425L507.024 63.3417C505.834 64.767 503.513 63.943 503.488 62.0866L503.452 59.3525C503.336 50.5975 499.401 42.3302 492.679 36.7195L490.58 34.9673C489.155 33.7775 489.978 31.4567 491.835 31.432L494.569 31.3958C503.324 31.2796 511.591 27.3445 517.202 20.6227L518.954 18.5235Z"
                        fill="#FFF46E"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                          delay: 0.4,
                        }}
                      />
                    </svg>

                    <svg
                      width="375"
                      height="79"
                      viewBox="0 0 375 79"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={cn(
                        "-translate-x-1/2 absolute left-1/2 block",
                        "md:hidden",
                      )}
                    >
                      <motion.path
                        d="M361.739 46.8982C362.454 46.0173 363.886 46.5401 363.9 47.6875C363.969 53.0343 366.345 58.1417 370.403 61.6255C371.257 62.3587 370.774 63.7583 369.661 63.7735C364.376 63.8456 359.401 66.2692 356.069 70.3744C355.354 71.2553 353.922 70.7325 353.907 69.5851C353.838 64.2383 351.463 59.1309 347.405 55.6471C346.551 54.9139 347.034 53.5143 348.146 53.4991C353.432 53.427 358.407 51.0034 361.739 46.8982Z"
                        fill="#FFF46E"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                          delay: 0.6,
                        }}
                      />
                      <motion.path
                        d="M364.553 8.61333C365.139 7.89125 366.307 8.31783 366.32 9.25831L366.352 11.8041C366.375 13.5647 367.157 15.2381 368.493 16.3852L370.415 18.0345C371.118 18.6381 370.72 19.7904 369.804 19.8028L367.302 19.837C365.561 19.8607 363.928 20.6523 362.831 22.0041L361.245 23.9586C360.659 24.6807 359.491 24.2541 359.478 23.3136L359.446 20.7679C359.423 19.0072 358.641 17.3339 357.305 16.1867L355.383 14.5374C354.68 13.9338 355.078 12.7816 355.994 12.7691L358.496 12.735C360.237 12.7112 361.87 11.9197 362.967 10.5679L364.553 8.61333Z"
                        fill="white"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                          delay: 0.4,
                        }}
                      />
                      <motion.path
                        d="M16.4777 4.18319C16.5703 3.39721 17.5893 3.14766 18.0164 3.80635C19.347 5.85818 21.5095 7.21393 23.9359 7.51642C24.701 7.61181 24.932 8.60953 24.2908 9.04934C22.2575 10.444 20.9211 12.6567 20.6326 15.1051C20.5399 15.8911 19.521 16.1406 19.0938 15.4819C17.7633 13.4301 15.6007 12.0743 13.1744 11.7718C12.4093 11.6765 12.1782 10.6787 12.8194 10.2389C14.8528 8.84422 16.1891 6.63157 16.4777 4.18319Z"
                        fill="white"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                        }}
                      />
                      <motion.path
                        d="M5.37383 34.34C5.92222 32.8158 8.07778 32.8158 8.62617 34.34C9.47978 36.7125 11.3427 38.5998 13.6992 39.4966C15.1974 40.0667 15.1974 42.1999 13.6992 42.77C11.3427 43.6668 9.47978 45.5541 8.62617 47.9266C8.07778 49.4508 5.92222 49.4508 5.37383 47.9266C4.52022 45.5541 2.65731 43.6668 0.300798 42.77C-1.19738 42.1999 -1.19738 40.0667 0.300798 39.4966C2.65731 38.5998 4.52022 36.7125 5.37383 34.34Z"
                        fill="#FFF46E"
                        variants={{
                          offscreen: {
                            opacity: 0,
                            scale: 0.5,
                          },
                          onscreen: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="offscreen" // 初期表示はoffscreen
                        whileInView="onscreen" // 画面内に入ったらonscreen
                        transition={{
                          ease: "easeInOut",
                          duration: 0.2,
                          delay: 0.2,
                        }}
                      />
                    </svg>

                    <Button
                      type="submit"
                      className={cn(
                        "relative h-[52px] w-[320px] text-[14px] md:h-14",
                      )}
                    >
                      <IconGoogle className="absolute left-[22px] size-6" />
                      <span className="font-medium text-[14px] md:text-[18px]">
                        <span className="font-poppins">Google</span>
                        <span className="flex-1 text-left">
                          ログインして登録
                        </span>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
