export const industryList = [
  { id: "1", name: "IT・通信" },
  { id: "2", name: "製造業" },
  { id: "3", name: "金融・保険" },
  { id: "4", name: "建設・不動産" },
  { id: "5", name: "小売・流通" },
  { id: "6", name: "医療・福祉" },
  { id: "7", name: "公務員・教育" },
  { id: "8", name: "広告・メディア" },
  { id: "9", name: "飲食・ホスピタリティ" },
  { id: "10", name: "エネルギー・資源" },
  { id: "11", name: "サービス業" },
  { id: "12", name: "農業・林業・漁業" },
  { id: "13", name: "マスコミ・出版" },
  { id: "14", name: "その他" },
] as const;
