import { cva } from "class-variance-authority";
import { cn } from "~/lib/utils";

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  tag?: "h1" | "h2" | "h3" | "h4";
}

const HeadingVariants = cva(" font-medium", {
  variants: {
    tag: {
      h1: "text-[35px] md:text-[39px] leading-[1.5]",
      h2: "text-[27px] md:text-[32px] leading-[1.5]",
      h3: "text-[16px] leading-[1.43] md:text-[22px] md:leading-[1.4]",
      h4: "text-[14px] leading-[1.42] md:text-[16px] md:leading-[1.75]",
    },
  },
  defaultVariants: {
    tag: "h1",
  },
});

const Heading = ({
  children,
  className,
  tag = "h1",
  ...props
}: HeadingProps) => {
  const Tag = tag;
  return (
    <Tag className={cn(HeadingVariants({ tag, className }))} {...props}>
      {children}
    </Tag>
  );
};

export { Heading, type HeadingProps };
