import { cn } from "~/lib/utils";

export const BgCoCreator = () => {
  return (
    <svg
      width="845"
      height="261"
      viewBox="0 0 845 261"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-[152px] w-[351px]", "md:h-[261px] md:w-[845px]")}
    >
      <path
        d="M526 143C538.133 149.667 550.267 149.667 562.4 143C574.533 136.333 586.667 136.333 598.8 143C610.933 149.667 623.067 149.667 635.2 143C647.333 136.333 659.467 136.333 671.6 143C683.733 149.667 695.867 149.667 708 143"
        stroke="#FFF46E"
        strokeWidth="6"
      />
      <path
        d="M526 155.5C538.167 161.5 550.333 161.5 562.5 155.5C574.667 149.5 586.833 149.5 599 155.5C611.167 161.5 623.333 161.5 635.5 155.5C647.667 149.5 659.833 149.5 672 155.5"
        stroke="#161615"
        strokeWidth="6"
      />
      {/* ペン */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M719.328 130.445L722.392 133.92L719.123 136.803L718.26 136.761C717.077 136.704 716.036 135.603 716.029 134.403L716.022 133.36L719.328 130.445Z"
        fill="#161615"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M722.109 121.75L731.377 132.262L722.759 136.908L716.458 129.762L722.109 121.75Z"
        fill="white"
      />
      {/* ペン本体 */}
      <path
        d="M795.073 60.1966C797.152 58.3635 800.324 58.5632 802.157 60.6427C803.99 62.7221 803.79 65.8938 801.711 67.7269L731.656 129.483L725.018 121.953L795.073 60.1966Z"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      {/* ここまで */}
      <path
        d="M39.5312 67.4757L71.0312 47.6328L71.7896 103.048L41.0312 125.519L39.5312 67.4757Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.21875 75.0625C0.21875 73.9579 1.11418 73.0625 2.21875 73.0625C3.32332 73.0625 4.21875 73.9579 4.21875 75.0625V100.731C4.21875 101.062 4.38212 101.371 4.6552 101.557L37.5284 123.983V98.3828H41.5284V123.658L70.6283 101.836C70.8802 101.647 71.0284 101.351 71.0284 101.036V75.3828C71.0284 74.2782 71.9238 73.3828 73.0284 73.3828C74.133 73.3828 75.0284 74.2782 75.0284 75.3828V101.036C75.0284 102.61 74.2873 104.092 73.0282 105.036L40.6502 129.317C39.9236 129.862 38.9471 129.837 38.2545 129.32L2.40102 104.862C1.0356 103.93 0.21875 102.384 0.21875 100.731V75.0625Z"
        fill="#FFF46E"
      />
      <path
        d="M2.03125 74.875L39.5312 98.3731L73.0312 76.4415"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2042 23.8446C38.5503 23.6237 38.9962 23.6368 39.3288 23.8776L70.0874 46.1508L71.0325 76.4937C71.0669 77.5977 71.9897 78.4648 73.0938 78.4305C74.1978 78.3961 75.0649 77.4732 75.0305 76.3692L74.054 45.0139C74.0304 44.2574 73.5897 43.6122 72.9594 43.2919L41.6748 20.6378C40.012 19.4337 37.7823 19.3684 36.0519 20.473L0.9241 42.8973C0.0702985 43.4423 -0.230071 44.5275 0.184326 45.4218L0.0315305 75.3738C0.0258957 76.4784 0.916744 77.3788 2.0213 77.3849C3.12585 77.3911 4.02583 76.5006 4.03147 75.3961L4.18366 45.5621L38.2042 23.8446ZM37.0002 68.0761V97.3182H41.0002V68.0761H37.0002Z"
        fill="#FFF46E"
      />
      <mask id="path-10-inside-1_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.41406 44.6533V45.0734H8.46012C8.92938 47.2271 12.9264 49.2841 17.7878 49.2841C22.6491 49.2841 26.6462 47.2271 27.1154 45.0734H27.1615V44.6533V35.7109H8.41406V44.6533Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41406 44.6533V45.0734H8.46012C8.92938 47.2271 12.9264 49.2841 17.7878 49.2841C22.6491 49.2841 26.6462 47.2271 27.1154 45.0734H27.1615V44.6533V35.7109H8.41406V44.6533Z"
        fill="white"
      />
      <path
        d="M8.41406 45.0734H4.41406V49.0734H8.41406V45.0734ZM8.46012 45.0734L12.3684 44.2219L11.6824 41.0734H8.46012V45.0734ZM27.1154 45.0734V41.0734H23.8931L23.2071 44.2219L27.1154 45.0734ZM27.1615 45.0734V49.0734H31.1615V45.0734H27.1615ZM27.1615 35.7109H31.1615V31.7109H27.1615V35.7109ZM8.41406 35.7109V31.7109H4.41406V35.7109H8.41406ZM12.4141 45.0734V44.6533H4.41406V45.0734H12.4141ZM8.46012 41.0734H8.41406V49.0734H8.46012V41.0734ZM17.7878 45.2841C15.8874 45.2841 14.2621 44.8761 13.1795 44.3669C12.6379 44.1121 12.3325 43.8795 12.2012 43.7536C12.0519 43.6103 12.2646 43.7454 12.3684 44.2219L4.55181 45.925C5.19674 48.885 7.79488 50.6749 9.77432 51.606C12.0228 52.6637 14.8267 53.2841 17.7878 53.2841V45.2841ZM23.2071 44.2219C23.3109 43.7454 23.5237 43.6103 23.3744 43.7536C23.2431 43.8795 22.9376 44.1121 22.3961 44.3669C21.3134 44.8761 19.6881 45.2841 17.7878 45.2841V53.2841C20.7488 53.2841 23.5527 52.6637 25.8012 51.606C27.7807 50.6749 30.3788 48.885 31.0237 45.925L23.2071 44.2219ZM27.1615 41.0734H27.1154V49.0734H27.1615V41.0734ZM23.1615 44.6533V45.0734H31.1615V44.6533H23.1615ZM23.1615 35.7109V44.6533H31.1615V35.7109H23.1615ZM8.41406 39.7109H27.1615V31.7109H8.41406V39.7109ZM12.4141 44.6533V35.7109H4.41406V44.6533H12.4141Z"
        fill="#FFF46E"
        mask="url(#path-10-inside-1_1102_4946)"
      />
      <path
        d="M25.1615 36.3136C25.1615 36.98 24.7211 37.8905 23.3598 38.737C22.0281 39.5652 20.0632 40.1428 17.7878 40.1428C15.5123 40.1428 13.5475 39.5652 12.2157 38.737C10.8545 37.8905 10.4141 36.98 10.4141 36.3136C10.4141 35.6472 10.8545 34.7366 12.2157 33.8901C13.5475 33.0619 15.5123 32.4844 17.7878 32.4844C20.0632 32.4844 22.0281 33.0619 23.3598 33.8901C24.7211 34.7366 25.1615 35.6472 25.1615 36.3136Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-13-inside-2_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1484 55.083V55.5031H28.1945C28.6638 57.6568 32.6608 59.7138 37.5221 59.7138C42.3835 59.7138 46.3805 57.6568 46.8498 55.5031H46.8959V55.083V46.1406H28.1484V55.083Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1484 55.083V55.5031H28.1945C28.6638 57.6568 32.6608 59.7138 37.5221 59.7138C42.3835 59.7138 46.3805 57.6568 46.8498 55.5031H46.8959V55.083V46.1406H28.1484V55.083Z"
        fill="#161615"
      />
      <path
        d="M28.1484 55.5031H24.1484V59.5031H28.1484V55.5031ZM28.1945 55.5031L32.1028 54.6516L31.4168 51.5031H28.1945V55.5031ZM46.8498 55.5031V51.5031H43.6275L42.9415 54.6516L46.8498 55.5031ZM46.8959 55.5031V59.5031H50.8959V55.5031H46.8959ZM46.8959 46.1406H50.8959V42.1406H46.8959V46.1406ZM28.1484 46.1406V42.1406H24.1484V46.1406H28.1484ZM32.1484 55.5031V55.083H24.1484V55.5031H32.1484ZM28.1945 51.5031H28.1484V59.5031H28.1945V51.5031ZM37.5221 55.7138C35.6218 55.7138 33.9965 55.3058 32.9139 54.7966C32.3723 54.5418 32.0668 54.3092 31.9356 54.1833C31.7862 54.04 31.999 54.1751 32.1028 54.6516L24.2862 56.3547C24.9311 59.3146 27.5293 61.1046 29.5087 62.0357C31.7572 63.0933 34.5611 63.7138 37.5221 63.7138V55.7138ZM42.9415 54.6516C43.0453 54.1751 43.2581 54.04 43.1087 54.1833C42.9775 54.3092 42.672 54.5418 42.1304 54.7966C41.0478 55.3058 39.4225 55.7138 37.5221 55.7138V63.7138C40.4832 63.7138 43.2871 63.0933 45.5356 62.0357C47.515 61.1046 50.1132 59.3146 50.7581 56.3547L42.9415 54.6516ZM46.8959 51.5031H46.8498V59.5031H46.8959V51.5031ZM42.8959 55.083V55.5031H50.8959V55.083H42.8959ZM42.8959 46.1406V55.083H50.8959V46.1406H42.8959ZM28.1484 50.1406H46.8959V42.1406H28.1484V50.1406ZM32.1484 55.083V46.1406H24.1484V55.083H32.1484Z"
        fill="#FFF46E"
        mask="url(#path-13-inside-2_1102_4946)"
      />
      <path
        d="M44.8959 46.7511C44.8959 47.4175 44.4554 48.328 43.0942 49.1745C41.7625 50.0027 39.7976 50.5803 37.5221 50.5803C35.2467 50.5803 33.2818 50.0027 31.9501 49.1745C30.5889 48.328 30.1484 47.4175 30.1484 46.7511C30.1484 46.0847 30.5889 45.1741 31.9501 44.3276C33.2818 43.4994 35.2467 42.9219 37.5221 42.9219C39.7976 42.9219 41.7625 43.4994 43.0942 44.3276C44.4554 45.1741 44.8959 46.0847 44.8959 46.7511Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-16-inside-3_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.8906 43.6064V44.0265H46.9367C47.4059 46.1803 51.403 48.2373 56.2643 48.2373C61.1257 48.2373 65.1227 46.1803 65.592 44.0265H65.638V43.6064V34.6641H46.8906V43.6064Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8906 43.6064V44.0265H46.9367C47.4059 46.1803 51.403 48.2373 56.2643 48.2373C61.1257 48.2373 65.1227 46.1803 65.592 44.0265H65.638V43.6064V34.6641H46.8906V43.6064Z"
        fill="#161615"
      />
      <path
        d="M46.8906 44.0265H42.8906V48.0265H46.8906V44.0265ZM46.9367 44.0265L50.845 43.175L50.159 40.0265H46.9367V44.0265ZM65.592 44.0265V40.0265H62.3697L61.6837 43.175L65.592 44.0265ZM65.638 44.0265V48.0265H69.638V44.0265H65.638ZM65.638 34.6641H69.638V30.6641H65.638V34.6641ZM46.8906 34.6641V30.6641H42.8906V34.6641H46.8906ZM50.8906 44.0265V43.6064H42.8906V44.0265H50.8906ZM46.9367 40.0265H46.8906V48.0265H46.9367V40.0265ZM56.2643 44.2373C54.364 44.2373 52.7387 43.8293 51.6561 43.32C51.1145 43.0653 50.809 42.8327 50.6777 42.7067C50.5284 42.5634 50.7412 42.6985 50.845 43.175L43.0284 44.8781C43.6733 47.8381 46.2714 49.628 48.2509 50.5591C50.4994 51.6168 53.3033 52.2373 56.2643 52.2373V44.2373ZM61.6837 43.175C61.7875 42.6985 62.0003 42.5634 61.8509 42.7067C61.7197 42.8327 61.4142 43.0653 60.8726 43.32C59.79 43.8293 58.1647 44.2373 56.2643 44.2373V52.2373C59.2254 52.2373 62.0293 51.6168 64.2778 50.5591C66.2572 49.628 68.8554 47.8381 69.5003 44.8781L61.6837 43.175ZM65.638 40.0265H65.592V48.0265H65.638V40.0265ZM61.638 43.6064V44.0265H69.638V43.6064H61.638ZM61.638 34.6641V43.6064H69.638V34.6641H61.638ZM46.8906 38.6641H65.638V30.6641H46.8906V38.6641ZM50.8906 43.6064V34.6641H42.8906V43.6064H50.8906Z"
        fill="#FFF46E"
        mask="url(#path-16-inside-3_1102_4946)"
      />
      <path
        d="M63.638 35.2745C63.638 35.9409 63.1976 36.8515 61.8364 37.698C60.5046 38.5261 58.5398 39.1037 56.2643 39.1037C53.9889 39.1037 52.024 38.5261 50.6923 37.698C49.3311 36.8515 48.8906 35.9409 48.8906 35.2745C48.8906 34.6081 49.3311 33.6975 50.6923 32.851C52.024 32.0229 53.9889 31.4453 56.2643 31.4453C58.5398 31.4453 60.5046 32.0229 61.8364 32.851C63.1976 33.6975 63.638 34.6081 63.638 35.2745Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-19-inside-4_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.1484 33.1689V33.589H28.1945C28.6638 35.7428 32.6608 37.7998 37.5221 37.7998C42.3835 37.7998 46.3805 35.7428 46.8498 33.589H46.8959V33.1689V24.2266H28.1484V33.1689Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1484 33.1689V33.589H28.1945C28.6638 35.7428 32.6608 37.7998 37.5221 37.7998C42.3835 37.7998 46.3805 35.7428 46.8498 33.589H46.8959V33.1689V24.2266H28.1484V33.1689Z"
        fill="white"
      />
      <path
        d="M28.1484 33.589H24.1484V37.589H28.1484V33.589ZM28.1945 33.589L32.1028 32.7375L31.4168 29.589H28.1945V33.589ZM46.8498 33.589V29.589H43.6275L42.9415 32.7375L46.8498 33.589ZM46.8959 33.589V37.589H50.8959V33.589H46.8959ZM46.8959 24.2266H50.8959V20.2266H46.8959V24.2266ZM28.1484 24.2266V20.2266H24.1484V24.2266H28.1484ZM32.1484 33.589V33.1689H24.1484V33.589H32.1484ZM28.1945 29.589H28.1484V37.589H28.1945V29.589ZM37.5221 33.7998C35.6218 33.7998 33.9965 33.3918 32.9139 32.8825C32.3723 32.6278 32.0668 32.3952 31.9356 32.2692C31.7862 32.1259 31.999 32.261 32.1028 32.7375L24.2862 34.4406C24.9311 37.4006 27.5293 39.1905 29.5087 40.1216C31.7572 41.1793 34.5611 41.7998 37.5221 41.7998V33.7998ZM42.9415 32.7375C43.0453 32.261 43.2581 32.1259 43.1087 32.2692C42.9775 32.3952 42.672 32.6278 42.1304 32.8825C41.0478 33.3918 39.4225 33.7998 37.5221 33.7998V41.7998C40.4832 41.7998 43.2871 41.1793 45.5356 40.1216C47.515 39.1905 50.1132 37.4006 50.7581 34.4406L42.9415 32.7375ZM46.8959 29.589H46.8498V37.589H46.8959V29.589ZM42.8959 33.1689V33.589H50.8959V33.1689H42.8959ZM42.8959 24.2266V33.1689H50.8959V24.2266H42.8959ZM28.1484 28.2266H46.8959V20.2266H28.1484V28.2266ZM32.1484 33.1689V24.2266H24.1484V33.1689H32.1484Z"
        fill="#FFF46E"
        mask="url(#path-19-inside-4_1102_4946)"
      />
      <path
        d="M44.8959 24.8292C44.8959 25.4956 44.4554 26.4062 43.0942 27.2527C41.7625 28.0808 39.7976 28.6584 37.5221 28.6584C35.2467 28.6584 33.2818 28.0808 31.9501 27.2527C30.5889 26.4062 30.1484 25.4956 30.1484 24.8292C30.1484 24.1628 30.5889 23.2522 31.9501 22.4057C33.2818 21.5776 35.2467 21 37.5221 21C39.7976 21 41.7625 21.5776 43.0942 22.4057C44.4554 23.2522 44.8959 24.1628 44.8959 24.8292Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <path
        d="M2 44.5781L39 68.0762L72.0533 46.1447"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      {/* びっくりマーク(黄色) */}
      <path
        d="M153.254 66.3033C153.148 68.3598 167.689 70.8729 169.955 69.9074C173.395 68.4415 176.76 68.2449 179.249 65.6738C182.785 62.0212 184.843 57.2715 185.944 52.547C186.714 49.241 187.335 45.8943 188.03 42.5705C188.301 41.2721 189.977 44.9543 191.288 45.8755C197.305 50.1022 202.019 53.596 209.807 53.5831C219.74 53.5667 224.014 44.0869 226.603 37.8416C227.339 36.0662 228.415 30.3973 228.022 32.2731C227.707 33.7827 228.109 34.1606 228.736 35.5613C231.257 41.1927 240.92 46.5215 247.388 48.1156C257.907 50.7081 258.904 45.255 265.346 40.2221"
        stroke="#FFF46E"
        strokeWidth="6"
        strokeLinecap="round"
      />
      {/* びっくりマーク(白) */}
      <path
        d="M150.389 55.0385C150.04 57.3505 164.225 60.8986 166.596 59.9223C170.195 58.4401 173.569 58.3842 176.351 55.6097C180.303 51.6682 182.913 46.4176 184.566 41.1482C185.723 37.4609 186.736 33.7205 187.82 30.0094C188.244 28.5597 189.478 32.7914 190.675 33.894C196.169 38.9531 200.451 43.1221 208.208 43.4912C218.103 43.9621 223.477 33.4908 226.792 26.5811C227.734 24.6168 229.474 18.2821 228.862 20.3764C228.37 22.0619 228.726 22.5075 229.185 24.1168C231.031 30.5864 240.026 37.0669 246.279 39.1817C256.449 42.6212 258.085 36.5258 265.094 31.172"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
      />
      <path
        d="M115.266 225.188L112.936 233.881"
        stroke="#FFF46E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M63.3672 210.242L61.8586 215.872"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M69.1562 211.797L67.6477 217.427"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M74.9531 213.352L73.4445 218.982"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M80.75 214.906L79.2414 220.536"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M92.3438 218.008L90.8352 223.638"
        stroke="#161615"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M98.1406 219.562L96.632 225.193"
        stroke="#161615"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M103.93 221.117L102.421 226.747"
        stroke="#161615"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M109.727 222.672L108.218 228.302"
        stroke="#161615"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M121.32 225.773L119.812 231.404"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M127.117 227.328L125.609 232.958"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M132.914 228.883L131.405 234.513"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M138.703 230.43L137.195 236.06"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M150.297 233.539L148.788 239.169"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M156.094 235.094L154.585 240.724"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M161.891 236.641L160.382 242.271"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M167.688 238.195L166.179 243.825"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M86.2891 217.422L83.9801 226.588"
        stroke="#FFF46E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M144.242 232.953L141.913 241.646"
        stroke="#FFF46E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <rect
        x="-2.44949"
        y="1.41421"
        width="123.381"
        height="18.4536"
        transform="matrix(-0.965926 -0.258819 -0.258819 0.965926 173.441 238.219)"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <path
        d="M810 183.884L844.378 162.656L841.758 186.829L811 208.074L810 183.884Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M808.734 140.649C809.067 140.45 809.484 140.462 809.805 140.679L841 161.751V184.787C841 185.111 840.843 185.415 840.579 185.603L811.5 206.218V182.409H807.5V206.529L774.649 185.341C774.363 185.157 774.191 184.84 774.191 184.501V161.218L808.734 140.649ZM770.191 161.046C769.756 160.125 770.084 159.008 770.977 158.476L806.687 137.212C808.352 136.221 810.439 136.28 812.044 137.364L843.889 158.875C844.546 159.201 844.998 159.878 845 160.661C845.001 160.68 845.001 160.699 845 160.718V184.787C845 186.408 844.214 187.929 842.892 188.866L810.683 211.701C810.029 212.232 809.084 212.31 808.338 211.829L772.481 188.703C771.053 187.782 770.191 186.199 770.191 184.501V161.046Z"
        fill="#FFF46E"
      />
      <mask id="path-47-inside-5_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M778.5 160.254V160.648H778.546C779.018 162.686 783.071 164.632 788 164.632C792.93 164.632 796.983 162.686 797.455 160.648H797.501V160.254V151.797H778.5V160.254Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M778.5 160.254V160.648H778.546C779.018 162.686 783.071 164.632 788 164.632C792.93 164.632 796.983 162.686 797.455 160.648H797.501V160.254V151.797H778.5V160.254Z"
        fill="white"
      />
      <path
        d="M778.5 160.648H774.5V164.648H778.5V160.648ZM778.546 160.648L782.443 159.745L781.725 156.648H778.546V160.648ZM797.455 160.648V156.648H794.276L793.558 159.745L797.455 160.648ZM797.501 160.648V164.648H801.501V160.648H797.501ZM797.501 151.797H801.501V147.797H797.501V151.797ZM778.5 151.797V147.797H774.5V151.797H778.5ZM782.5 160.648V160.254H774.5V160.648H782.5ZM778.546 156.648H778.5V164.648H778.546V156.648ZM788 160.632C786.027 160.632 784.337 160.237 783.21 159.742C782.644 159.493 782.329 159.268 782.199 159.151C782.042 159.011 782.312 159.182 782.443 159.745L774.649 161.551C775.016 163.134 775.97 164.312 776.86 165.109C777.776 165.931 778.871 166.574 779.993 167.067C782.242 168.054 785.044 168.632 788 168.632V160.632ZM793.558 159.745C793.689 159.182 793.959 159.011 793.802 159.151C793.672 159.268 793.357 159.493 792.791 159.742C791.663 160.237 789.973 160.632 788 160.632V168.632C790.957 168.632 793.758 168.054 796.007 167.067C797.13 166.574 798.224 165.931 799.141 165.109C800.031 164.312 800.985 163.134 801.352 161.551L793.558 159.745ZM797.501 156.648H797.455V164.648H797.501V156.648ZM793.501 160.254V160.648H801.501V160.254H793.501ZM793.501 151.797V160.254H801.501V151.797H793.501ZM778.5 155.797H797.501V147.797H778.5V155.797ZM782.5 160.254V151.797H774.5V160.254H782.5Z"
        fill="#FFF46E"
        mask="url(#path-47-inside-5_1102_4946)"
      />
      <path
        d="M795.501 152.37C795.501 152.88 795.129 153.717 793.715 154.537C792.351 155.328 790.335 155.881 788 155.881C785.665 155.881 783.65 155.328 782.286 154.537C780.872 153.717 780.5 152.88 780.5 152.37C780.5 151.861 780.872 151.024 782.286 150.203C783.65 149.413 785.665 148.859 788 148.859C790.335 148.859 792.351 149.413 793.715 150.203C795.129 151.024 795.501 151.861 795.501 152.37Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-50-inside-6_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M798.5 170.129V170.523H798.546C799.018 172.561 803.071 174.507 808 174.507C812.93 174.507 816.983 172.561 817.455 170.523H817.501V170.129V161.672H798.5V170.129Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M798.5 170.129V170.523H798.546C799.018 172.561 803.071 174.507 808 174.507C812.93 174.507 816.983 172.561 817.455 170.523H817.501V170.129V161.672H798.5V170.129Z"
        fill="#161615"
      />
      <path
        d="M798.5 170.523H794.5V174.523H798.5V170.523ZM798.546 170.523L802.443 169.62L801.725 166.523H798.546V170.523ZM817.455 170.523V166.523H814.276L813.558 169.62L817.455 170.523ZM817.501 170.523V174.523H821.501V170.523H817.501ZM817.501 161.672H821.501V157.672H817.501V161.672ZM798.5 161.672V157.672H794.5V161.672H798.5ZM802.5 170.523V170.129H794.5V170.523H802.5ZM798.546 166.523H798.5V174.523H798.546V166.523ZM808 170.507C806.027 170.507 804.337 170.112 803.21 169.617C802.644 169.368 802.329 169.143 802.199 169.026C802.042 168.886 802.312 169.057 802.443 169.62L794.649 171.426C795.016 173.009 795.97 174.187 796.86 174.984C797.776 175.806 798.871 176.449 799.993 176.942C802.242 177.929 805.044 178.507 808 178.507V170.507ZM813.558 169.62C813.689 169.057 813.959 168.886 813.802 169.026C813.672 169.143 813.357 169.368 812.791 169.617C811.663 170.112 809.973 170.507 808 170.507V178.507C810.957 178.507 813.758 177.929 816.007 176.942C817.13 176.449 818.224 175.806 819.141 174.984C820.031 174.187 820.985 173.009 821.352 171.426L813.558 169.62ZM817.501 166.523H817.455V174.523H817.501V166.523ZM813.501 170.129V170.523H821.501V170.129H813.501ZM813.501 161.672V170.129H821.501V161.672H813.501ZM798.5 165.672H817.501V157.672H798.5V165.672ZM802.5 170.129V161.672H794.5V170.129H802.5Z"
        fill="#FFF46E"
        mask="url(#path-50-inside-6_1102_4946)"
      />
      <path
        d="M815.501 162.245C815.501 162.755 815.129 163.592 813.715 164.412C812.351 165.203 810.335 165.756 808 165.756C805.665 165.756 803.65 165.203 802.286 164.412C800.872 163.592 800.5 162.755 800.5 162.245C800.5 161.736 800.872 160.899 802.286 160.078C803.65 159.288 805.665 158.734 808 158.734C810.335 158.734 812.351 159.288 813.715 160.078C815.129 160.899 815.501 161.736 815.501 162.245Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-53-inside-7_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M817.5 159.27V159.664H817.546C818.018 161.701 822.071 163.648 827 163.648C831.93 163.648 835.983 161.701 836.455 159.664H836.501V159.27V150.812H817.5V159.27Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M817.5 159.27V159.664H817.546C818.018 161.701 822.071 163.648 827 163.648C831.93 163.648 835.983 161.701 836.455 159.664H836.501V159.27V150.812H817.5V159.27Z"
        fill="#161615"
      />
      <path
        d="M817.5 159.664H813.5V163.664H817.5V159.664ZM817.546 159.664L821.443 158.761L820.725 155.664H817.546V159.664ZM836.455 159.664V155.664H833.276L832.558 158.761L836.455 159.664ZM836.501 159.664V163.664H840.501V159.664H836.501ZM836.501 150.812H840.501V146.812H836.501V150.812ZM817.5 150.812V146.812H813.5V150.812H817.5ZM821.5 159.664V159.27H813.5V159.664H821.5ZM817.546 155.664H817.5V163.664H817.546V155.664ZM827 159.648C825.027 159.648 823.337 159.253 822.21 158.757C821.644 158.509 821.329 158.283 821.199 158.167C821.042 158.026 821.312 158.197 821.443 158.761L813.649 160.567C814.016 162.149 814.97 163.327 815.86 164.125C816.776 164.946 817.871 165.589 818.993 166.082C821.242 167.07 824.044 167.648 827 167.648V159.648ZM832.558 158.761C832.689 158.197 832.959 158.026 832.802 158.167C832.672 158.283 832.357 158.509 831.791 158.757C830.663 159.253 828.973 159.648 827 159.648V167.648C829.957 167.648 832.758 167.07 835.007 166.082C836.13 165.589 837.224 164.946 838.141 164.125C839.031 163.327 839.985 162.149 840.352 160.567L832.558 158.761ZM836.501 155.664H836.455V163.664H836.501V155.664ZM832.501 159.27V159.664H840.501V159.27H832.501ZM832.501 150.812V159.27H840.501V150.812H832.501ZM817.5 154.812H836.501V146.812H817.5V154.812ZM821.5 159.27V150.812H813.5V159.27H821.5Z"
        fill="#FFF46E"
        mask="url(#path-53-inside-7_1102_4946)"
      />
      <path
        d="M834.501 151.386C834.501 151.895 834.129 152.732 832.715 153.553C831.351 154.344 829.335 154.897 827 154.897C824.665 154.897 822.65 154.344 821.286 153.553C819.872 152.732 819.5 151.895 819.5 151.386C819.5 150.876 819.872 150.04 821.286 149.219C822.65 148.428 824.665 147.875 827 147.875C829.335 147.875 831.351 148.428 832.715 149.219C834.129 150.04 834.501 150.876 834.501 151.386Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-56-inside-8_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M798.5 149.395V149.789H798.546C799.018 151.826 803.071 153.773 808 153.773C812.93 153.773 816.983 151.826 817.455 149.789H817.501V149.395V140.938H798.5V149.395Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M798.5 149.395V149.789H798.546C799.018 151.826 803.071 153.773 808 153.773C812.93 153.773 816.983 151.826 817.455 149.789H817.501V149.395V140.938H798.5V149.395Z"
        fill="white"
      />
      <path
        d="M798.5 149.789H794.5V153.789H798.5V149.789ZM798.546 149.789L802.443 148.886L801.725 145.789H798.546V149.789ZM817.455 149.789V145.789H814.276L813.558 148.886L817.455 149.789ZM817.501 149.789V153.789H821.501V149.789H817.501ZM817.501 140.938H821.501V136.938H817.501V140.938ZM798.5 140.938V136.938H794.5V140.938H798.5ZM802.5 149.789V149.395H794.5V149.789H802.5ZM798.546 145.789H798.5V153.789H798.546V145.789ZM808 149.773C806.027 149.773 804.337 149.378 803.21 148.882C802.644 148.634 802.329 148.408 802.199 148.292C802.042 148.151 802.312 148.322 802.443 148.886L794.649 150.692C795.016 152.274 795.97 153.452 796.86 154.25C797.776 155.071 798.871 155.714 799.993 156.207C802.242 157.195 805.044 157.773 808 157.773V149.773ZM813.558 148.886C813.689 148.322 813.959 148.151 813.802 148.292C813.672 148.408 813.357 148.634 812.791 148.882C811.663 149.378 809.973 149.773 808 149.773V157.773C810.957 157.773 813.758 157.195 816.007 156.207C817.13 155.714 818.224 155.071 819.141 154.25C820.031 153.452 820.985 152.274 821.352 150.692L813.558 148.886ZM817.501 145.789H817.455V153.789H817.501V145.789ZM813.501 149.395V149.789H821.501V149.395H813.501ZM813.501 140.938V149.395H821.501V140.938H813.501ZM798.5 144.938H817.501V136.938H798.5V144.938ZM802.5 149.395V140.938H794.5V149.395H802.5Z"
        fill="#FFF46E"
        mask="url(#path-56-inside-8_1102_4946)"
      />
      <path
        d="M815.501 141.511C815.501 142.02 815.129 142.857 813.715 143.678C812.351 144.469 810.335 145.022 808 145.022C805.665 145.022 803.65 144.469 802.286 143.678C800.872 142.857 800.5 142.02 800.5 141.511C800.5 141.001 800.872 140.165 802.286 139.344C803.65 138.553 805.665 138 808 138C810.335 138 812.351 138.553 813.715 139.344C815.129 140.165 815.501 141.001 815.501 141.511Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <path
        d="M772 160.188L809.5 182.403L843 161.669"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <path
        d="M109 142.884L143.378 121.656L140.758 145.829L110 167.074L109 142.884Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.734 99.6486C108.067 99.4504 108.484 99.4623 108.805 99.6791L140 120.751V143.787C140 144.111 139.843 144.415 139.579 144.603L110.5 165.218V141.409H106.5V165.529L73.6487 144.341C73.3632 144.157 73.1907 143.84 73.1907 143.501V120.218L107.734 99.6486ZM69.1907 120.046C68.7559 119.125 69.0842 118.008 69.9771 117.476L105.687 96.2118C107.352 95.2207 109.439 95.2802 111.044 96.3645L142.889 117.875C143.546 118.201 143.998 118.878 144 119.661C144.001 119.68 144.001 119.699 144 119.718V143.787C144 145.408 143.214 146.929 141.892 147.866L109.683 170.701C109.029 171.232 108.084 171.31 107.338 170.829L71.4807 147.703C70.0531 146.782 69.1907 145.199 69.1907 143.501V120.046Z"
        fill="#FFF46E"
      />
      <mask id="path-62-inside-9_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.5 119.254V119.648H77.5459C78.018 121.686 82.0707 123.632 87.0004 123.632C91.93 123.632 95.9828 121.686 96.4548 119.648H96.5008V119.254V110.797H77.5V119.254Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.5 119.254V119.648H77.5459C78.018 121.686 82.0707 123.632 87.0004 123.632C91.93 123.632 95.9828 121.686 96.4548 119.648H96.5008V119.254V110.797H77.5V119.254Z"
        fill="white"
      />
      <path
        d="M77.5 119.648H73.5V123.648H77.5V119.648ZM77.5459 119.648L81.4427 118.745L80.7251 115.648H77.5459V119.648ZM96.4548 119.648V115.648H93.2757L92.5581 118.745L96.4548 119.648ZM96.5008 119.648V123.648H100.501V119.648H96.5008ZM96.5008 110.797H100.501V106.797H96.5008V110.797ZM77.5 110.797V106.797H73.5V110.797H77.5ZM81.5 119.648V119.254H73.5V119.648H81.5ZM77.5459 115.648H77.5V123.648H77.5459V115.648ZM87.0004 119.632C85.0274 119.632 83.3375 119.237 82.2097 118.742C81.6439 118.493 81.3285 118.268 81.1986 118.151C81.0416 118.011 81.3121 118.182 81.4427 118.745L73.6492 120.551C74.0158 122.134 74.9699 123.312 75.8596 124.109C76.7764 124.931 77.8708 125.574 78.9934 126.067C81.2424 127.054 84.0437 127.632 87.0004 127.632V119.632ZM92.5581 118.745C92.6887 118.182 92.9592 118.011 92.8021 118.151C92.6722 118.268 92.3568 118.493 91.7911 118.742C90.6633 119.237 88.9734 119.632 87.0004 119.632V127.632C89.957 127.632 92.7584 127.054 95.0074 126.067C96.13 125.574 97.2244 124.931 98.1412 124.109C99.0309 123.312 99.9849 122.134 100.352 120.551L92.5581 118.745ZM96.5008 115.648H96.4548V123.648H96.5008V115.648ZM92.5008 119.254V119.648H100.501V119.254H92.5008ZM92.5008 110.797V119.254H100.501V110.797H92.5008ZM77.5 114.797H96.5008V106.797H77.5V114.797ZM81.5 119.254V110.797H73.5V119.254H81.5Z"
        fill="#FFF46E"
        mask="url(#path-62-inside-9_1102_4946)"
      />
      <path
        d="M94.5008 111.37C94.5008 111.88 94.1292 112.717 92.7146 113.537C91.3512 114.328 89.3353 114.881 87.0004 114.881C84.6655 114.881 82.6496 114.328 81.2861 113.537C79.8716 112.717 79.5 111.88 79.5 111.37C79.5 110.861 79.8716 110.024 81.2861 109.203C82.6496 108.413 84.6655 107.859 87.0004 107.859C89.3353 107.859 91.3512 108.413 92.7146 109.203C94.1292 110.024 94.5008 110.861 94.5008 111.37Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-65-inside-10_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.5 129.129V129.523H97.5459C98.018 131.561 102.071 133.507 107 133.507C111.93 133.507 115.983 131.561 116.455 129.523H116.501V129.129V120.672H97.5V129.129Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.5 129.129V129.523H97.5459C98.018 131.561 102.071 133.507 107 133.507C111.93 133.507 115.983 131.561 116.455 129.523H116.501V129.129V120.672H97.5V129.129Z"
        fill="#161615"
      />
      <path
        d="M97.5 129.523H93.5V133.523H97.5V129.523ZM97.5459 129.523L101.443 128.62L100.725 125.523H97.5459V129.523ZM116.455 129.523V125.523H113.276L112.558 128.62L116.455 129.523ZM116.501 129.523V133.523H120.501V129.523H116.501ZM116.501 120.672H120.501V116.672H116.501V120.672ZM97.5 120.672V116.672H93.5V120.672H97.5ZM101.5 129.523V129.129H93.5V129.523H101.5ZM97.5459 125.523H97.5V133.523H97.5459V125.523ZM107 129.507C105.027 129.507 103.337 129.112 102.21 128.617C101.644 128.368 101.329 128.143 101.199 128.026C101.042 127.886 101.312 128.057 101.443 128.62L93.6492 130.426C94.0158 132.009 94.9699 133.187 95.8596 133.984C96.7764 134.806 97.8708 135.449 98.9934 135.942C101.242 136.929 104.044 137.507 107 137.507V129.507ZM112.558 128.62C112.689 128.057 112.959 127.886 112.802 128.026C112.672 128.143 112.357 128.368 111.791 128.617C110.663 129.112 108.973 129.507 107 129.507V137.507C109.957 137.507 112.758 136.929 115.007 135.942C116.13 135.449 117.224 134.806 118.141 133.984C119.031 133.187 119.985 132.009 120.352 130.426L112.558 128.62ZM116.501 125.523H116.455V133.523H116.501V125.523ZM112.501 129.129V129.523H120.501V129.129H112.501ZM112.501 120.672V129.129H120.501V120.672H112.501ZM97.5 124.672H116.501V116.672H97.5V124.672ZM101.5 129.129V120.672H93.5V129.129H101.5Z"
        fill="#FFF46E"
        mask="url(#path-65-inside-10_1102_4946)"
      />
      <path
        d="M114.501 121.245C114.501 121.755 114.129 122.592 112.715 123.412C111.351 124.203 109.335 124.756 107 124.756C104.665 124.756 102.65 124.203 101.286 123.412C99.8716 122.592 99.5 121.755 99.5 121.245C99.5 120.736 99.8716 119.899 101.286 119.078C102.65 118.288 104.665 117.734 107 117.734C109.335 117.734 111.351 118.288 112.715 119.078C114.129 119.899 114.501 120.736 114.501 121.245Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-68-inside-11_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.5 118.27V118.664H116.546C117.018 120.701 121.071 122.648 126 122.648C130.93 122.648 134.983 120.701 135.455 118.664H135.501V118.27V109.812H116.5V118.27Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.5 118.27V118.664H116.546C117.018 120.701 121.071 122.648 126 122.648C130.93 122.648 134.983 120.701 135.455 118.664H135.501V118.27V109.812H116.5V118.27Z"
        fill="#161615"
      />
      <path
        d="M116.5 118.664H112.5V122.664H116.5V118.664ZM116.546 118.664L120.443 117.761L119.725 114.664H116.546V118.664ZM135.455 118.664V114.664H132.276L131.558 117.761L135.455 118.664ZM135.501 118.664V122.664H139.501V118.664H135.501ZM135.501 109.812H139.501V105.812H135.501V109.812ZM116.5 109.812V105.812H112.5V109.812H116.5ZM120.5 118.664V118.27H112.5V118.664H120.5ZM116.546 114.664H116.5V122.664H116.546V114.664ZM126 118.648C124.027 118.648 122.337 118.253 121.21 117.757C120.644 117.509 120.329 117.283 120.199 117.167C120.042 117.026 120.312 117.197 120.443 117.761L112.649 119.567C113.016 121.149 113.97 122.327 114.86 123.125C115.776 123.946 116.871 124.589 117.993 125.082C120.242 126.07 123.044 126.648 126 126.648V118.648ZM131.558 117.761C131.689 117.197 131.959 117.026 131.802 117.167C131.672 117.283 131.357 117.509 130.791 117.757C129.663 118.253 127.973 118.648 126 118.648V126.648C128.957 126.648 131.758 126.07 134.007 125.082C135.13 124.589 136.224 123.946 137.141 123.125C138.031 122.327 138.985 121.149 139.352 119.567L131.558 117.761ZM135.501 114.664H135.455V122.664H135.501V114.664ZM131.501 118.27V118.664H139.501V118.27H131.501ZM131.501 109.812V118.27H139.501V109.812H131.501ZM116.5 113.812H135.501V105.812H116.5V113.812ZM120.5 118.27V109.812H112.5V118.27H120.5Z"
        fill="#FFF46E"
        mask="url(#path-68-inside-11_1102_4946)"
      />
      <path
        d="M133.501 110.386C133.501 110.895 133.129 111.732 131.715 112.553C130.351 113.344 128.335 113.897 126 113.897C123.665 113.897 121.65 113.344 120.286 112.553C118.872 111.732 118.5 110.895 118.5 110.386C118.5 109.876 118.872 109.04 120.286 108.219C121.65 107.428 123.665 106.875 126 106.875C128.335 106.875 130.351 107.428 131.715 108.219C133.129 109.04 133.501 109.876 133.501 110.386Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <mask id="path-71-inside-12_1102_4946" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.5 108.395V108.789H97.5459C98.018 110.826 102.071 112.773 107 112.773C111.93 112.773 115.983 110.826 116.455 108.789H116.501V108.395V99.9375H97.5V108.395Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.5 108.395V108.789H97.5459C98.018 110.826 102.071 112.773 107 112.773C111.93 112.773 115.983 110.826 116.455 108.789H116.501V108.395V99.9375H97.5V108.395Z"
        fill="white"
      />
      <path
        d="M97.5 108.789H93.5V112.789H97.5V108.789ZM97.5459 108.789L101.443 107.886L100.725 104.789H97.5459V108.789ZM116.455 108.789V104.789H113.276L112.558 107.886L116.455 108.789ZM116.501 108.789V112.789H120.501V108.789H116.501ZM116.501 99.9375H120.501V95.9375H116.501V99.9375ZM97.5 99.9375V95.9375H93.5V99.9375H97.5ZM101.5 108.789V108.395H93.5V108.789H101.5ZM97.5459 104.789H97.5V112.789H97.5459V104.789ZM107 108.773C105.027 108.773 103.337 108.378 102.21 107.882C101.644 107.634 101.329 107.408 101.199 107.292C101.042 107.151 101.312 107.322 101.443 107.886L93.6492 109.692C94.0158 111.274 94.9699 112.452 95.8596 113.25C96.7764 114.071 97.8708 114.714 98.9934 115.207C101.242 116.195 104.044 116.773 107 116.773V108.773ZM112.558 107.886C112.689 107.322 112.959 107.151 112.802 107.292C112.672 107.408 112.357 107.634 111.791 107.882C110.663 108.378 108.973 108.773 107 108.773V116.773C109.957 116.773 112.758 116.195 115.007 115.207C116.13 114.714 117.224 114.071 118.141 113.25C119.031 112.452 119.985 111.274 120.352 109.692L112.558 107.886ZM116.501 104.789H116.455V112.789H116.501V104.789ZM112.501 108.395V108.789H120.501V108.395H112.501ZM112.501 99.9375V108.395H120.501V99.9375H112.501ZM97.5 103.938H116.501V95.9375H97.5V103.938ZM101.5 108.395V99.9375H93.5V108.395H101.5Z"
        fill="#FFF46E"
        mask="url(#path-71-inside-12_1102_4946)"
      />
      <path
        d="M114.501 100.511C114.501 101.02 114.129 101.857 112.715 102.678C111.351 103.469 109.335 104.022 107 104.022C104.665 104.022 102.65 103.469 101.286 102.678C99.8716 101.857 99.5 101.02 99.5 100.511C99.5 100.001 99.8716 99.1647 101.286 98.3441C102.65 97.5532 104.665 97 107 97C109.335 97 111.351 97.5532 112.715 98.3441C114.129 99.1647 114.501 100.001 114.501 100.511Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="4"
      />
      <path
        d="M71 119.188L108.5 141.403L142 120.669"
        stroke="#FFF46E"
        strokeWidth="4"
      />
    </svg>
  );
};

export const BgCoCreatorSp = () => {
  return (
    <svg
      width="364"
      height="157"
      viewBox="0 0 364 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* wave */}
      <path
        d="M205 133.5C211.867 136.833 218.733 136.833 225.6 133.5C232.467 130.167 239.333 130.167 246.2 133.5C253.067 136.833 259.933 136.833 266.8 133.5C273.667 130.167 280.533 130.167 287.4 133.5C294.267 136.833 301.133 136.833 308 133.5"
        stroke="#FFF46E"
        strokeWidth="3"
      />
      <path
        d="M205 139.5C211.917 142.833 218.833 142.833 225.75 139.5C232.667 136.167 239.583 136.167 246.5 139.5C253.417 142.833 260.333 142.833 267.25 139.5C274.167 136.167 281.083 136.167 288 139.5"
        stroke="#161615"
        strokeWidth="3"
      />
      {/* ここまで */}
      <path
        d="M64.875 136.781L63.5782 141.621"
        stroke="#FFF46E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M35.9766 128.461L35.1367 131.595"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M39.2031 129.32L38.3633 132.455"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M42.4219 130.195L41.582 133.33"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M45.6562 131.055L44.8164 134.189"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M52.1094 132.789L51.2695 135.923"
        stroke="#161615"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M55.3438 133.648L54.5039 136.783"
        stroke="#161615"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M58.5625 134.516L57.7227 137.65"
        stroke="#161615"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M61.7891 135.375L60.9492 138.509"
        stroke="#161615"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M68.25 137.102L67.4102 140.236"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M71.4688 137.969L70.6289 141.103"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M74.6953 138.836L73.8555 141.97"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M77.9219 139.695L77.082 142.83"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M84.375 141.43L83.5352 144.564"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M87.6016 142.297L86.7617 145.431"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M90.8203 143.156L89.9805 146.291"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M94.0547 144.016L93.2149 147.15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48.7422 132.453L47.4568 137.556"
        stroke="#FFF46E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M81 141.102L79.7032 145.941"
        stroke="#FFF46E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect
        x="-1.22475"
        y="0.707107"
        width="68.9132"
        height="10.4999"
        transform="matrix(-0.965926 -0.258819 -0.258819 0.965926 97.5096 144.066)"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <path
        d="M265.633 31.437L287.918 17.5L286.22 33.3706L266.281 47.3186L265.633 31.437Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.074 3.13111C264.745 2.72663 265.59 2.75084 266.236 3.19307L286.024 16.7306V31.4999C286.024 32.1441 285.714 32.7488 285.191 33.1245L266.313 46.68V30.4585H264.313V46.8591L243.03 32.9567C242.464 32.5874 242.123 31.9576 242.123 31.2823V16.3696L264.074 3.13111ZM240.123 16.36C239.866 15.8923 240.023 15.301 240.484 15.0229L263.041 1.41847C264.383 0.609499 266.073 0.657931 267.366 1.54239L287.437 15.274C287.781 15.4302 288.022 15.7758 288.024 16.1779C288.025 16.1951 288.025 16.2123 288.024 16.2295V31.4999C288.024 32.7882 287.404 33.9976 286.357 34.749L265.896 49.4416C265.567 49.7138 265.087 49.7538 264.711 49.5082L241.936 34.6311C240.805 33.8924 240.123 32.633 240.123 31.2823V16.36Z"
        fill="#FFF46E"
      />
      <mask id="path-25-inside-1_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M245.219 15.9109V16.1706H245.249C245.556 17.5078 248.182 18.7853 251.377 18.7853C254.572 18.7853 257.198 17.5078 257.506 16.1706H257.536V15.9109V10.3594H245.219V15.9109Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.219 15.9109V16.1706H245.249C245.556 17.5078 248.182 18.7853 251.377 18.7853C254.572 18.7853 257.198 17.5078 257.506 16.1706H257.536V15.9109V10.3594H245.219V15.9109Z"
        fill="white"
      />
      <path
        d="M245.219 16.1706H243.219V18.1706H245.219V16.1706ZM245.249 16.1706L247.198 15.7229L246.841 14.1706H245.249V16.1706ZM257.506 16.1706V14.1706H255.913L255.556 15.7229L257.506 16.1706ZM257.536 16.1706V18.1706H259.536V16.1706H257.536ZM257.536 10.3594H259.536V8.35938H257.536V10.3594ZM245.219 10.3594V8.35938H243.219V10.3594H245.219ZM247.219 16.1706V15.9109H243.219V16.1706H247.219ZM245.249 14.1706H245.219V18.1706H245.249V14.1706ZM251.377 16.7853C250.029 16.7853 248.855 16.5128 248.043 16.1519C247.636 15.971 247.374 15.7919 247.23 15.6619C247.074 15.5204 247.153 15.5264 247.198 15.7229L243.3 16.6184C243.498 17.4835 244.02 18.1506 244.543 18.6247C245.078 19.1103 245.73 19.5013 246.418 19.8069C247.794 20.419 249.531 20.7853 251.377 20.7853V16.7853ZM255.556 15.7229C255.602 15.5264 255.68 15.5204 255.524 15.6619C255.381 15.7919 255.119 15.971 254.712 16.1519C253.9 16.5128 252.725 16.7853 251.377 16.7853V20.7853C253.224 20.7853 254.96 20.419 256.337 19.8069C257.024 19.5013 257.676 19.1103 258.211 18.6247C258.734 18.1506 259.256 17.4835 259.455 16.6184L255.556 15.7229ZM257.536 14.1706H257.506V18.1706H257.536V14.1706ZM255.536 15.9109V16.1706H259.536V15.9109H255.536ZM255.536 10.3594V15.9109H259.536V10.3594H255.536ZM245.219 12.3594H257.536V8.35938H245.219V12.3594ZM247.219 15.9109V10.3594H243.219V15.9109H247.219Z"
        fill="#FFF46E"
        mask="url(#path-25-inside-1_1356_2923)"
      />
      <path
        d="M256.536 10.7432C256.536 11.2425 256.184 11.8759 255.225 12.4394C254.29 12.9891 252.931 13.3613 251.377 13.3613C249.823 13.3613 248.465 12.9891 247.529 12.4394C246.57 11.8759 246.219 11.2425 246.219 10.7432C246.219 10.2438 246.57 9.61039 247.529 9.04694C248.465 8.4972 249.823 8.125 251.377 8.125C252.931 8.125 254.29 8.4972 255.225 9.04694C256.184 9.61039 256.536 10.2438 256.536 10.7432Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-28-inside-2_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258.172 22.4109V22.6706H258.202C258.509 24.0078 261.136 25.2853 264.33 25.2853C267.525 25.2853 270.152 24.0078 270.459 22.6706H270.489V22.4109V16.8594H258.172V22.4109Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.172 22.4109V22.6706H258.202C258.509 24.0078 261.136 25.2853 264.33 25.2853C267.525 25.2853 270.152 24.0078 270.459 22.6706H270.489V22.4109V16.8594H258.172V22.4109Z"
        fill="#161615"
      />
      <path
        d="M258.172 22.6706H256.172V24.6706H258.172V22.6706ZM258.202 22.6706L260.151 22.2229L259.795 20.6706H258.202V22.6706ZM270.459 22.6706V20.6706H268.866L268.51 22.2229L270.459 22.6706ZM270.489 22.6706V24.6706H272.489V22.6706H270.489ZM270.489 16.8594H272.489V14.8594H270.489V16.8594ZM258.172 16.8594V14.8594H256.172V16.8594H258.172ZM260.172 22.6706V22.4109H256.172V22.6706H260.172ZM258.202 20.6706H258.172V24.6706H258.202V20.6706ZM264.33 23.2853C262.982 23.2853 261.808 23.0128 260.996 22.6519C260.589 22.471 260.327 22.2919 260.183 22.1619C260.028 22.0204 260.106 22.0264 260.151 22.2229L256.253 23.1184C256.451 23.9835 256.973 24.6506 257.496 25.1247C258.032 25.6103 258.683 26.0013 259.371 26.3069C260.748 26.919 262.484 27.2853 264.33 27.2853V23.2853ZM268.51 22.2229C268.555 22.0264 268.633 22.0204 268.477 22.1619C268.334 22.2919 268.072 22.471 267.665 22.6519C266.853 23.0128 265.678 23.2853 264.33 23.2853V27.2853C266.177 27.2853 267.913 26.919 269.29 26.3069C269.977 26.0013 270.629 25.6103 271.165 25.1247C271.687 24.6506 272.209 23.9835 272.408 23.1184L268.51 22.2229ZM270.489 20.6706H270.459V24.6706H270.489V20.6706ZM268.489 22.4109V22.6706H272.489V22.4109H268.489ZM268.489 16.8594V22.4109H272.489V16.8594H268.489ZM258.172 18.8594H270.489V14.8594H258.172V18.8594ZM260.172 22.4109V16.8594H256.172V22.4109H260.172Z"
        fill="#FFF46E"
        mask="url(#path-28-inside-2_1356_2923)"
      />
      <path
        d="M269.489 17.2354C269.489 17.7347 269.137 18.3681 268.178 18.9316C267.243 19.4813 265.884 19.8535 264.33 19.8535C262.776 19.8535 261.418 19.4813 260.482 18.9316C259.523 18.3681 259.172 17.7347 259.172 17.2354C259.172 16.736 259.523 16.1026 260.482 15.5391C261.418 14.9894 262.776 14.6172 264.33 14.6172C265.884 14.6172 267.243 14.9894 268.178 15.5391C269.137 16.1026 269.489 16.736 269.489 17.2354Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-31-inside-3_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M270.5 15.2625V15.5222H270.53C270.837 16.8594 273.464 18.1368 276.658 18.1368C279.853 18.1368 282.48 16.8594 282.787 15.5222H282.817V15.2625V9.71094H270.5V15.2625Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M270.5 15.2625V15.5222H270.53C270.837 16.8594 273.464 18.1368 276.658 18.1368C279.853 18.1368 282.48 16.8594 282.787 15.5222H282.817V15.2625V9.71094H270.5V15.2625Z"
        fill="#161615"
      />
      <path
        d="M270.5 15.5222H268.5V17.5222H270.5V15.5222ZM270.53 15.5222L272.479 15.0744L272.123 13.5222H270.53V15.5222ZM282.787 15.5222V13.5222H281.194L280.838 15.0744L282.787 15.5222ZM282.817 15.5222V17.5222H284.817V15.5222H282.817ZM282.817 9.71094H284.817V7.71094H282.817V9.71094ZM270.5 9.71094V7.71094H268.5V9.71094H270.5ZM272.5 15.5222V15.2625H268.5V15.5222H272.5ZM270.53 13.5222H270.5V17.5222H270.53V13.5222ZM276.658 16.1368C275.31 16.1368 274.136 15.8644 273.324 15.5034C272.917 15.3226 272.655 15.1435 272.512 15.0135C272.356 14.872 272.434 14.8779 272.479 15.0744L268.581 15.97C268.78 16.8351 269.301 17.5021 269.824 17.9763C270.36 18.4619 271.011 18.8528 271.699 19.1585C273.076 19.7705 274.812 20.1368 276.658 20.1368V16.1368ZM280.838 15.0744C280.883 14.8779 280.961 14.872 280.805 15.0135C280.662 15.1435 280.4 15.3226 279.993 15.5034C279.181 15.8644 278.007 16.1368 276.658 16.1368V20.1368C278.505 20.1368 280.241 19.7705 281.618 19.1585C282.305 18.8528 282.957 18.4619 283.493 17.9763C284.015 17.5021 284.537 16.8351 284.736 15.97L280.838 15.0744ZM282.817 13.5222H282.787V17.5222H282.817V13.5222ZM280.817 15.2625V15.5222H284.817V15.2625H280.817ZM280.817 9.71094V15.2625H284.817V9.71094H280.817ZM270.5 11.7109H282.817V7.71094H270.5V11.7109ZM272.5 15.2625V9.71094H268.5V15.2625H272.5Z"
        fill="#FFF46E"
        mask="url(#path-31-inside-3_1356_2923)"
      />
      <path
        d="M281.817 10.0947C281.817 10.5941 281.466 11.2275 280.507 11.7909C279.571 12.3407 278.213 12.7129 276.658 12.7129C275.104 12.7129 273.746 12.3407 272.81 11.7909C271.851 11.2275 271.5 10.5941 271.5 10.0947C271.5 9.59534 271.851 8.96195 272.81 8.3985C273.746 7.84876 275.104 7.47656 276.658 7.47656C278.213 7.47656 279.571 7.84876 280.507 8.3985C281.466 8.96195 281.817 9.59534 281.817 10.0947Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-34-inside-4_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258.172 8.79372V9.05345H258.202C258.509 10.3906 261.136 11.6681 264.33 11.6681C267.525 11.6681 270.152 10.3906 270.459 9.05345H270.489V8.79372V3.24219H258.172V8.79372Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.172 8.79372V9.05345H258.202C258.509 10.3906 261.136 11.6681 264.33 11.6681C267.525 11.6681 270.152 10.3906 270.459 9.05345H270.489V8.79372V3.24219H258.172V8.79372Z"
        fill="white"
      />
      <path
        d="M258.172 9.05345H256.172V11.0535H258.172V9.05345ZM258.202 9.05345L260.151 8.60569L259.795 7.05345H258.202V9.05345ZM270.459 9.05345V7.05345H268.866L268.51 8.60569L270.459 9.05345ZM270.489 9.05345V11.0535H272.489V9.05345H270.489ZM270.489 3.24219H272.489V1.24219H270.489V3.24219ZM258.172 3.24219V1.24219H256.172V3.24219H258.172ZM260.172 9.05345V8.79372H256.172V9.05345H260.172ZM258.202 7.05345H258.172V11.0535H258.202V7.05345ZM264.33 9.66808C262.982 9.66808 261.808 9.39566 260.996 9.03466C260.589 8.85382 260.327 8.67472 260.183 8.54472C260.028 8.40326 260.106 8.40917 260.151 8.60569L256.253 9.50122C256.451 10.3663 256.973 11.0334 257.496 11.5075C258.032 11.9931 258.683 12.3841 259.371 12.6898C260.748 13.3018 262.484 13.6681 264.33 13.6681V9.66808ZM268.51 8.60569C268.555 8.40917 268.633 8.40326 268.477 8.54472C268.334 8.67472 268.072 8.85382 267.665 9.03466C266.853 9.39566 265.678 9.66808 264.33 9.66808V13.6681C266.177 13.6681 267.913 13.3018 269.29 12.6898C269.977 12.3841 270.629 11.9931 271.165 11.5075C271.687 11.0334 272.209 10.3663 272.408 9.50122L268.51 8.60569ZM270.489 7.05345H270.459V11.0535H270.489V7.05345ZM268.489 8.79372V9.05345H272.489V8.79372H268.489ZM268.489 3.24219V8.79372H272.489V3.24219H268.489ZM258.172 5.24219H270.489V1.24219H258.172V5.24219ZM260.172 8.79372V3.24219H256.172V8.79372H260.172Z"
        fill="#FFF46E"
        mask="url(#path-34-inside-4_1356_2923)"
      />
      <path
        d="M269.489 3.61816C269.489 4.11755 269.137 4.75094 268.178 5.31438C267.243 5.86412 265.884 6.23633 264.33 6.23633C262.776 6.23633 261.418 5.86412 260.482 5.31438C259.523 4.75094 259.172 4.11755 259.172 3.61816C259.172 3.11878 259.523 2.48539 260.482 1.92194C261.418 1.3722 262.776 1 264.33 1C265.884 1 267.243 1.3722 268.178 1.92194C269.137 2.48539 269.489 3.11878 269.489 3.61816Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <path
        d="M241 15.8828L265.309 30.468L287.024 16.8552"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <path
        d="M316.633 61.437L338.918 47.5L337.22 63.3706L317.281 77.3186L316.633 61.437Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M315.074 33.1311C315.745 32.7266 316.59 32.7508 317.236 33.1931L337.024 46.7306V61.4999C337.024 62.1441 336.714 62.7488 336.191 63.1245L317.313 76.68V60.4585H315.313V76.8591L294.03 62.9567C293.464 62.5874 293.123 61.9576 293.123 61.2823V46.3696L315.074 33.1311ZM291.123 46.36C290.866 45.8923 291.023 45.301 291.484 45.0229L314.041 31.4185C315.383 30.6095 317.073 30.6579 318.366 31.5424L338.437 45.274C338.781 45.4302 339.022 45.7758 339.024 46.1779C339.025 46.1951 339.025 46.2123 339.024 46.2295V61.4999C339.024 62.7882 338.404 63.9976 337.357 64.749L316.896 79.4416C316.567 79.7138 316.087 79.7538 315.711 79.5082L292.936 64.6311C291.805 63.8924 291.123 62.633 291.123 61.2823V46.36Z"
        fill="#FFF46E"
      />
      <mask id="path-40-inside-5_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M296.219 45.9109V46.1706H296.249C296.556 47.5078 299.182 48.7853 302.377 48.7853C305.572 48.7853 308.198 47.5078 308.506 46.1706H308.536V45.9109V40.3594H296.219V45.9109Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.219 45.9109V46.1706H296.249C296.556 47.5078 299.182 48.7853 302.377 48.7853C305.572 48.7853 308.198 47.5078 308.506 46.1706H308.536V45.9109V40.3594H296.219V45.9109Z"
        fill="white"
      />
      <path
        d="M296.219 46.1706H294.219V48.1706H296.219V46.1706ZM296.249 46.1706L298.198 45.7229L297.841 44.1706H296.249V46.1706ZM308.506 46.1706V44.1706H306.913L306.556 45.7229L308.506 46.1706ZM308.536 46.1706V48.1706H310.536V46.1706H308.536ZM308.536 40.3594H310.536V38.3594H308.536V40.3594ZM296.219 40.3594V38.3594H294.219V40.3594H296.219ZM298.219 46.1706V45.9109H294.219V46.1706H298.219ZM296.249 44.1706H296.219V48.1706H296.249V44.1706ZM302.377 46.7853C301.029 46.7853 299.855 46.5128 299.043 46.1519C298.636 45.971 298.374 45.7919 298.23 45.6619C298.074 45.5204 298.153 45.5264 298.198 45.7229L294.3 46.6184C294.498 47.4835 295.02 48.1506 295.543 48.6247C296.078 49.1103 296.73 49.5013 297.418 49.8069C298.794 50.419 300.531 50.7853 302.377 50.7853V46.7853ZM306.556 45.7229C306.602 45.5264 306.68 45.5204 306.524 45.6619C306.381 45.7919 306.119 45.971 305.712 46.1519C304.9 46.5128 303.725 46.7853 302.377 46.7853V50.7853C304.224 50.7853 305.96 50.419 307.337 49.8069C308.024 49.5013 308.676 49.1103 309.211 48.6247C309.734 48.1506 310.256 47.4835 310.455 46.6184L306.556 45.7229ZM308.536 44.1706H308.506V48.1706H308.536V44.1706ZM306.536 45.9109V46.1706H310.536V45.9109H306.536ZM306.536 40.3594V45.9109H310.536V40.3594H306.536ZM296.219 42.3594H308.536V38.3594H296.219V42.3594ZM298.219 45.9109V40.3594H294.219V45.9109H298.219Z"
        fill="#FFF46E"
        mask="url(#path-40-inside-5_1356_2923)"
      />
      <path
        d="M307.536 40.7432C307.536 41.2425 307.184 41.8759 306.225 42.4394C305.29 42.9891 303.931 43.3613 302.377 43.3613C300.823 43.3613 299.465 42.9891 298.529 42.4394C297.57 41.8759 297.219 41.2425 297.219 40.7432C297.219 40.2438 297.57 39.6104 298.529 39.0469C299.465 38.4972 300.823 38.125 302.377 38.125C303.931 38.125 305.29 38.4972 306.225 39.0469C307.184 39.6104 307.536 40.2438 307.536 40.7432Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-43-inside-6_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M309.172 52.4109V52.6706H309.202C309.509 54.0078 312.136 55.2853 315.33 55.2853C318.525 55.2853 321.152 54.0078 321.459 52.6706H321.489V52.4109V46.8594H309.172V52.4109Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.172 52.4109V52.6706H309.202C309.509 54.0078 312.136 55.2853 315.33 55.2853C318.525 55.2853 321.152 54.0078 321.459 52.6706H321.489V52.4109V46.8594H309.172V52.4109Z"
        fill="#161615"
      />
      <path
        d="M309.172 52.6706H307.172V54.6706H309.172V52.6706ZM309.202 52.6706L311.151 52.2229L310.795 50.6706H309.202V52.6706ZM321.459 52.6706V50.6706H319.866L319.51 52.2229L321.459 52.6706ZM321.489 52.6706V54.6706H323.489V52.6706H321.489ZM321.489 46.8594H323.489V44.8594H321.489V46.8594ZM309.172 46.8594V44.8594H307.172V46.8594H309.172ZM311.172 52.6706V52.4109H307.172V52.6706H311.172ZM309.202 50.6706H309.172V54.6706H309.202V50.6706ZM315.33 53.2853C313.982 53.2853 312.808 53.0128 311.996 52.6519C311.589 52.471 311.327 52.2919 311.183 52.1619C311.028 52.0204 311.106 52.0264 311.151 52.2229L307.253 53.1184C307.451 53.9835 307.973 54.6506 308.496 55.1247C309.032 55.6103 309.683 56.0013 310.371 56.3069C311.748 56.919 313.484 57.2853 315.33 57.2853V53.2853ZM319.51 52.2229C319.555 52.0264 319.633 52.0204 319.477 52.1619C319.334 52.2919 319.072 52.471 318.665 52.6519C317.853 53.0128 316.678 53.2853 315.33 53.2853V57.2853C317.177 57.2853 318.913 56.919 320.29 56.3069C320.977 56.0013 321.629 55.6103 322.165 55.1247C322.687 54.6506 323.209 53.9835 323.408 53.1184L319.51 52.2229ZM321.489 50.6706H321.459V54.6706H321.489V50.6706ZM319.489 52.4109V52.6706H323.489V52.4109H319.489ZM319.489 46.8594V52.4109H323.489V46.8594H319.489ZM309.172 48.8594H321.489V44.8594H309.172V48.8594ZM311.172 52.4109V46.8594H307.172V52.4109H311.172Z"
        fill="#FFF46E"
        mask="url(#path-43-inside-6_1356_2923)"
      />
      <path
        d="M320.489 47.2354C320.489 47.7347 320.137 48.3681 319.178 48.9316C318.243 49.4813 316.884 49.8535 315.33 49.8535C313.776 49.8535 312.418 49.4813 311.482 48.9316C310.523 48.3681 310.172 47.7347 310.172 47.2354C310.172 46.736 310.523 46.1026 311.482 45.5391C312.418 44.9894 313.776 44.6172 315.33 44.6172C316.884 44.6172 318.243 44.9894 319.178 45.5391C320.137 46.1026 320.489 46.736 320.489 47.2354Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-46-inside-7_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M321.5 45.2625V45.5222H321.53C321.837 46.8594 324.464 48.1368 327.658 48.1368C330.853 48.1368 333.48 46.8594 333.787 45.5222H333.817V45.2625V39.7109H321.5V45.2625Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M321.5 45.2625V45.5222H321.53C321.837 46.8594 324.464 48.1368 327.658 48.1368C330.853 48.1368 333.48 46.8594 333.787 45.5222H333.817V45.2625V39.7109H321.5V45.2625Z"
        fill="#161615"
      />
      <path
        d="M321.5 45.5222H319.5V47.5222H321.5V45.5222ZM321.53 45.5222L323.479 45.0744L323.123 43.5222H321.53V45.5222ZM333.787 45.5222V43.5222H332.194L331.838 45.0744L333.787 45.5222ZM333.817 45.5222V47.5222H335.817V45.5222H333.817ZM333.817 39.7109H335.817V37.7109H333.817V39.7109ZM321.5 39.7109V37.7109H319.5V39.7109H321.5ZM323.5 45.5222V45.2625H319.5V45.5222H323.5ZM321.53 43.5222H321.5V47.5222H321.53V43.5222ZM327.658 46.1368C326.31 46.1368 325.136 45.8644 324.324 45.5034C323.917 45.3226 323.655 45.1435 323.512 45.0135C323.356 44.872 323.434 44.8779 323.479 45.0744L319.581 45.97C319.78 46.8351 320.301 47.5021 320.824 47.9763C321.36 48.4619 322.011 48.8528 322.699 49.1585C324.076 49.7705 325.812 50.1368 327.658 50.1368V46.1368ZM331.838 45.0744C331.883 44.8779 331.961 44.872 331.805 45.0135C331.662 45.1435 331.4 45.3226 330.993 45.5034C330.181 45.8644 329.007 46.1368 327.658 46.1368V50.1368C329.505 50.1368 331.241 49.7705 332.618 49.1585C333.305 48.8528 333.957 48.4619 334.493 47.9763C335.015 47.5021 335.537 46.8351 335.736 45.97L331.838 45.0744ZM333.817 43.5222H333.787V47.5222H333.817V43.5222ZM331.817 45.2625V45.5222H335.817V45.2625H331.817ZM331.817 39.7109V45.2625H335.817V39.7109H331.817ZM321.5 41.7109H333.817V37.7109H321.5V41.7109ZM323.5 45.2625V39.7109H319.5V45.2625H323.5Z"
        fill="#FFF46E"
        mask="url(#path-46-inside-7_1356_2923)"
      />
      <path
        d="M332.817 40.0947C332.817 40.5941 332.466 41.2275 331.507 41.7909C330.571 42.3407 329.213 42.7129 327.658 42.7129C326.104 42.7129 324.746 42.3407 323.81 41.7909C322.851 41.2275 322.5 40.5941 322.5 40.0947C322.5 39.5953 322.851 38.9619 323.81 38.3985C324.746 37.8488 326.104 37.4766 327.658 37.4766C329.213 37.4766 330.571 37.8488 331.507 38.3985C332.466 38.9619 332.817 39.5953 332.817 40.0947Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-49-inside-8_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M309.172 38.7937V39.0535H309.202C309.509 40.3906 312.136 41.6681 315.33 41.6681C318.525 41.6681 321.152 40.3906 321.459 39.0535H321.489V38.7937V33.2422H309.172V38.7937Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.172 38.7937V39.0535H309.202C309.509 40.3906 312.136 41.6681 315.33 41.6681C318.525 41.6681 321.152 40.3906 321.459 39.0535H321.489V38.7937V33.2422H309.172V38.7937Z"
        fill="white"
      />
      <path
        d="M309.172 39.0535H307.172V41.0535H309.172V39.0535ZM309.202 39.0535L311.151 38.6057L310.795 37.0535H309.202V39.0535ZM321.459 39.0535V37.0535H319.866L319.51 38.6057L321.459 39.0535ZM321.489 39.0535V41.0535H323.489V39.0535H321.489ZM321.489 33.2422H323.489V31.2422H321.489V33.2422ZM309.172 33.2422V31.2422H307.172V33.2422H309.172ZM311.172 39.0535V38.7937H307.172V39.0535H311.172ZM309.202 37.0535H309.172V41.0535H309.202V37.0535ZM315.33 39.6681C313.982 39.6681 312.808 39.3957 311.996 39.0347C311.589 38.8538 311.327 38.6747 311.183 38.5447C311.028 38.4033 311.106 38.4092 311.151 38.6057L307.253 39.5012C307.451 40.3663 307.973 41.0334 308.496 41.5075C309.032 41.9931 309.683 42.3841 310.371 42.6898C311.748 43.3018 313.484 43.6681 315.33 43.6681V39.6681ZM319.51 38.6057C319.555 38.4092 319.633 38.4033 319.477 38.5447C319.334 38.6747 319.072 38.8538 318.665 39.0347C317.853 39.3957 316.678 39.6681 315.33 39.6681V43.6681C317.177 43.6681 318.913 43.3018 320.29 42.6898C320.977 42.3841 321.629 41.9931 322.165 41.5075C322.687 41.0334 323.209 40.3663 323.408 39.5012L319.51 38.6057ZM321.489 37.0535H321.459V41.0535H321.489V37.0535ZM319.489 38.7937V39.0535H323.489V38.7937H319.489ZM319.489 33.2422V38.7937H323.489V33.2422H319.489ZM309.172 35.2422H321.489V31.2422H309.172V35.2422ZM311.172 38.7937V33.2422H307.172V38.7937H311.172Z"
        fill="#FFF46E"
        mask="url(#path-49-inside-8_1356_2923)"
      />
      <path
        d="M320.489 33.6182C320.489 34.1175 320.137 34.7509 319.178 35.3144C318.243 35.8641 316.884 36.2363 315.33 36.2363C313.776 36.2363 312.418 35.8641 311.482 35.3144C310.523 34.7509 310.172 34.1175 310.172 33.6182C310.172 33.1188 310.523 32.4854 311.482 31.9219C312.418 31.3722 313.776 31 315.33 31C316.884 31 318.243 31.3722 319.178 31.9219C320.137 32.4854 320.489 33.1188 320.489 33.6182Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <path
        d="M292 45.8828L316.309 60.468L338.024 46.8552"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <path
        d="M18.6328 60.4412L39.918 47.2188V79L18.6328 94.0042V60.4412Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0741 32.1311C17.7448 31.7266 18.5898 31.7508 19.2363 32.1931L39.0244 45.7306V60.4999C39.0244 61.1441 38.7141 61.7488 38.1909 62.1245L19.3126 75.68V59.4585H17.3126V75.8591L-3.97044 61.9567C-4.53585 61.5874 -4.87668 60.9576 -4.87668 60.2823V45.3696L17.0741 32.1311ZM-6.87668 45.36C-7.13361 44.8923 -6.97745 44.301 -6.5163 44.0229L16.0412 30.4185C17.3826 29.6095 19.0727 29.6579 20.3655 30.5424L40.4372 44.274C40.7815 44.4302 41.0215 44.7758 41.0243 45.1779C41.0248 45.1951 41.0248 45.2123 41.0244 45.2295V60.4999C41.0244 61.7882 40.4039 62.9976 39.3574 63.749L18.8957 78.4416C18.5671 78.7138 18.0871 78.7538 17.7111 78.5082L-5.06419 63.6311C-6.19502 62.8924 -6.87668 61.633 -6.87668 60.2823V45.36Z"
        fill="#FFF46E"
      />
      <path d="M18.3125 76.4688V94.6192" stroke="#FFF46E" strokeWidth="2" />
      <path
        d="M18.2578 94.6787L38.774 79.947C39.5588 79.3835 40.0242 78.4764 40.0242 77.5102V62.1953"
        stroke="#FFF46E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18.2578 94.6813L-4.51746 79.8042C-5.36558 79.2502 -5.87683 78.3056 -5.87683 77.2926V62"
        stroke="#FFF46E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <mask id="path-58-inside-9_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.78125 44.9109V45.1706H-1.75123C-1.44406 46.5078 1.18249 47.7853 4.37719 47.7853C7.5719 47.7853 10.1985 46.5078 10.5056 45.1706H10.5356V44.9109V39.3594H-1.78125V44.9109Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.78125 44.9109V45.1706H-1.75123C-1.44406 46.5078 1.18249 47.7853 4.37719 47.7853C7.5719 47.7853 10.1985 46.5078 10.5056 45.1706H10.5356V44.9109V39.3594H-1.78125V44.9109Z"
        fill="white"
      />
      <path
        d="M-1.78125 45.1706H-3.78125V47.1706H-1.78125V45.1706ZM-1.75123 45.1706L0.198002 44.7229L-0.158567 43.1706H-1.75123V45.1706ZM10.5056 45.1706V43.1706H8.91296L8.55639 44.7229L10.5056 45.1706ZM10.5356 45.1706V47.1706H12.5356V45.1706H10.5356ZM10.5356 39.3594H12.5356V37.3594H10.5356V39.3594ZM-1.78125 39.3594V37.3594H-3.78125V39.3594H-1.78125ZM0.21875 45.1706V44.9109H-3.78125V45.1706H0.21875ZM-1.75123 43.1706H-1.78125V47.1706H-1.75123V43.1706ZM4.37719 45.7853C3.02913 45.7853 1.85464 45.5128 1.0426 45.1519C0.635799 44.971 0.373665 44.7919 0.23034 44.6619C0.0743829 44.5204 0.152859 44.5264 0.198002 44.7229L-3.70046 45.6184C-3.50173 46.4835 -2.97975 47.1506 -2.457 47.6247C-1.92162 48.1103 -1.26986 48.5013 -0.582289 48.8069C0.794411 49.419 2.53055 49.7853 4.37719 49.7853V45.7853ZM8.55639 44.7229C8.60153 44.5264 8.68001 44.5204 8.52405 44.6619C8.38073 44.7919 8.11859 44.971 7.71179 45.1519C6.89974 45.5128 5.72526 45.7853 4.37719 45.7853V49.7853C6.22384 49.7853 7.95998 49.419 9.33668 48.8069C10.0242 48.5013 10.676 48.1103 11.2114 47.6247C11.7341 47.1506 12.2561 46.4835 12.4549 45.6184L8.55639 44.7229ZM10.5356 43.1706H10.5056V47.1706H10.5356V43.1706ZM8.53564 44.9109V45.1706H12.5356V44.9109H8.53564ZM8.53564 39.3594V44.9109H12.5356V39.3594H8.53564ZM-1.78125 41.3594H10.5356V37.3594H-1.78125V41.3594ZM0.21875 44.9109V39.3594H-3.78125V44.9109H0.21875Z"
        fill="#FFF46E"
        mask="url(#path-58-inside-9_1356_2923)"
      />
      <path
        d="M9.53564 39.7432C9.53564 40.2425 9.18435 40.8759 8.22532 41.4394C7.28961 41.9891 5.93136 42.3613 4.37719 42.3613C2.82303 42.3613 1.46478 41.9891 0.529074 41.4394C-0.429959 40.8759 -0.78125 40.2425 -0.78125 39.7432C-0.78125 39.2438 -0.429959 38.6104 0.529074 38.0469C1.46478 37.4972 2.82303 37.125 4.37719 37.125C5.93136 37.125 7.28961 37.4972 8.22532 38.0469C9.18435 38.6104 9.53564 39.2438 9.53564 39.7432Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-61-inside-10_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1719 51.4109V51.6706H11.2019C11.5091 53.0078 14.1356 54.2853 17.3303 54.2853C20.525 54.2853 23.1516 53.0078 23.4587 51.6706H23.4888V51.4109V45.8594H11.1719V51.4109Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1719 51.4109V51.6706H11.2019C11.5091 53.0078 14.1356 54.2853 17.3303 54.2853C20.525 54.2853 23.1516 53.0078 23.4587 51.6706H23.4888V51.4109V45.8594H11.1719V51.4109Z"
        fill="#161615"
      />
      <path
        d="M11.1719 51.6706H9.17188V53.6706H11.1719V51.6706ZM11.2019 51.6706L13.1511 51.2229L12.7946 49.6706H11.2019V51.6706ZM23.4587 51.6706V49.6706H21.8661L21.5095 51.2229L23.4587 51.6706ZM23.4888 51.6706V53.6706H25.4888V51.6706H23.4888ZM23.4888 45.8594H25.4888V43.8594H23.4888V45.8594ZM11.1719 45.8594V43.8594H9.17188V45.8594H11.1719ZM13.1719 51.6706V51.4109H9.17188V51.6706H13.1719ZM11.2019 49.6706H11.1719V53.6706H11.2019V49.6706ZM17.3303 52.2853C15.9823 52.2853 14.8078 52.0128 13.9957 51.6519C13.5889 51.471 13.3268 51.2919 13.1835 51.1619C13.0275 51.0204 13.106 51.0264 13.1511 51.2229L9.25266 52.1184C9.45139 52.9835 9.97337 53.6506 10.4961 54.1247C11.0315 54.6103 11.6833 55.0013 12.3708 55.3069C13.7475 55.919 15.4837 56.2853 17.3303 56.2853V52.2853ZM21.5095 51.2229C21.5547 51.0264 21.6331 51.0204 21.4772 51.1619C21.3339 51.2919 21.0717 51.471 20.6649 51.6519C19.8529 52.0128 18.6784 52.2853 17.3303 52.2853V56.2853C19.177 56.2853 20.9131 55.919 22.2898 55.3069C22.9774 55.0013 23.6291 54.6103 24.1645 54.1247C24.6873 53.6506 25.2092 52.9835 25.408 52.1184L21.5095 51.2229ZM23.4888 49.6706H23.4587V53.6706H23.4888V49.6706ZM21.4888 51.4109V51.6706H25.4888V51.4109H21.4888ZM21.4888 45.8594V51.4109H25.4888V45.8594H21.4888ZM11.1719 47.8594H23.4888V43.8594H11.1719V47.8594ZM13.1719 51.4109V45.8594H9.17188V51.4109H13.1719Z"
        fill="#FFF46E"
        mask="url(#path-61-inside-10_1356_2923)"
      />
      <path
        d="M22.4888 46.2354C22.4888 46.7347 22.1375 47.3681 21.1784 47.9316C20.2427 48.4813 18.8845 48.8535 17.3303 48.8535C15.7762 48.8535 14.4179 48.4813 13.4822 47.9316C12.5232 47.3681 12.1719 46.7347 12.1719 46.2354C12.1719 45.736 12.5232 45.1026 13.4822 44.5391C14.4179 43.9894 15.7762 43.6172 17.3303 43.6172C18.8845 43.6172 20.2427 43.9894 21.1784 44.5391C22.1375 45.1026 22.4888 45.736 22.4888 46.2354Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-64-inside-11_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5 44.2625V44.5222H23.53C23.8372 45.8594 26.4637 47.1368 29.6584 47.1368C32.8532 47.1368 35.4797 45.8594 35.7869 44.5222H35.8169V44.2625V38.7109H23.5V44.2625Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 44.2625V44.5222H23.53C23.8372 45.8594 26.4637 47.1368 29.6584 47.1368C32.8532 47.1368 35.4797 45.8594 35.7869 44.5222H35.8169V44.2625V38.7109H23.5V44.2625Z"
        fill="#161615"
      />
      <path
        d="M23.5 44.5222H21.5V46.5222H23.5V44.5222ZM23.53 44.5222L25.4793 44.0744L25.1227 42.5222H23.53V44.5222ZM35.7869 44.5222V42.5222H34.1942L33.8376 44.0744L35.7869 44.5222ZM35.8169 44.5222V46.5222H37.8169V44.5222H35.8169ZM35.8169 38.7109H37.8169V36.7109H35.8169V38.7109ZM23.5 38.7109V36.7109H21.5V38.7109H23.5ZM25.5 44.5222V44.2625H21.5V44.5222H25.5ZM23.53 42.5222H23.5V46.5222H23.53V42.5222ZM29.6584 45.1368C28.3104 45.1368 27.1359 44.8644 26.3239 44.5034C25.917 44.3226 25.6549 44.1435 25.5116 44.0135C25.3556 43.872 25.4341 43.8779 25.4793 44.0744L21.5808 44.97C21.7795 45.8351 22.3015 46.5021 22.8242 46.9763C23.3596 47.4619 24.0114 47.8528 24.699 48.1585C26.0757 48.7705 27.8118 49.1368 29.6584 49.1368V45.1368ZM33.8376 44.0744C33.8828 43.8779 33.9613 43.872 33.8053 44.0135C33.662 44.1435 33.3998 44.3226 32.993 44.5034C32.181 44.8644 31.0065 45.1368 29.6584 45.1368V49.1368C31.5051 49.1368 33.2412 48.7705 34.6179 48.1585C35.3055 47.8528 35.9573 47.4619 36.4926 46.9763C37.0154 46.5021 37.5374 45.8351 37.7361 44.97L33.8376 44.0744ZM35.8169 42.5222H35.7869V46.5222H35.8169V42.5222ZM33.8169 44.2625V44.5222H37.8169V44.2625H33.8169ZM33.8169 38.7109V44.2625H37.8169V38.7109H33.8169ZM23.5 40.7109H35.8169V36.7109H23.5V40.7109ZM25.5 44.2625V38.7109H21.5V44.2625H25.5Z"
        fill="#FFF46E"
        mask="url(#path-64-inside-11_1356_2923)"
      />
      <path
        d="M34.8169 39.0947C34.8169 39.5941 34.4656 40.2275 33.5066 40.7909C32.5709 41.3407 31.2126 41.7129 29.6584 41.7129C28.1043 41.7129 26.746 41.3407 25.8103 40.7909C24.8513 40.2275 24.5 39.5941 24.5 39.0947C24.5 38.5953 24.8513 37.9619 25.8103 37.3985C26.746 36.8488 28.1043 36.4766 29.6584 36.4766C31.2126 36.4766 32.5709 36.8488 33.5066 37.3985C34.4656 37.9619 34.8169 38.5953 34.8169 39.0947Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <mask id="path-67-inside-12_1356_2923" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1719 37.7937V38.0535H11.2019C11.5091 39.3906 14.1356 40.6681 17.3303 40.6681C20.525 40.6681 23.1516 39.3906 23.4587 38.0535H23.4888V37.7937V32.2422H11.1719V37.7937Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1719 37.7937V38.0535H11.2019C11.5091 39.3906 14.1356 40.6681 17.3303 40.6681C20.525 40.6681 23.1516 39.3906 23.4587 38.0535H23.4888V37.7937V32.2422H11.1719V37.7937Z"
        fill="white"
      />
      <path
        d="M11.1719 38.0535H9.17188V40.0535H11.1719V38.0535ZM11.2019 38.0535L13.1511 37.6057L12.7946 36.0535H11.2019V38.0535ZM23.4587 38.0535V36.0535H21.8661L21.5095 37.6057L23.4587 38.0535ZM23.4888 38.0535V40.0535H25.4888V38.0535H23.4888ZM23.4888 32.2422H25.4888V30.2422H23.4888V32.2422ZM11.1719 32.2422V30.2422H9.17188V32.2422H11.1719ZM13.1719 38.0535V37.7937H9.17188V38.0535H13.1719ZM11.2019 36.0535H11.1719V40.0535H11.2019V36.0535ZM17.3303 38.6681C15.9823 38.6681 14.8078 38.3957 13.9957 38.0347C13.5889 37.8538 13.3268 37.6747 13.1835 37.5447C13.0275 37.4033 13.106 37.4092 13.1511 37.6057L9.25266 38.5012C9.45139 39.3663 9.97337 40.0334 10.4961 40.5075C11.0315 40.9931 11.6833 41.3841 12.3708 41.6898C13.7475 42.3018 15.4837 42.6681 17.3303 42.6681V38.6681ZM21.5095 37.6057C21.5547 37.4092 21.6331 37.4033 21.4772 37.5447C21.3339 37.6747 21.0717 37.8538 20.6649 38.0347C19.8529 38.3957 18.6784 38.6681 17.3303 38.6681V42.6681C19.177 42.6681 20.9131 42.3018 22.2898 41.6898C22.9774 41.3841 23.6291 40.9931 24.1645 40.5075C24.6873 40.0334 25.2092 39.3663 25.408 38.5012L21.5095 37.6057ZM23.4888 36.0535H23.4587V40.0535H23.4888V36.0535ZM21.4888 37.7937V38.0535H25.4888V37.7937H21.4888ZM21.4888 32.2422V37.7937H25.4888V32.2422H21.4888ZM11.1719 34.2422H23.4888V30.2422H11.1719V34.2422ZM13.1719 37.7937V32.2422H9.17188V37.7937H13.1719Z"
        fill="#FFF46E"
        mask="url(#path-67-inside-12_1356_2923)"
      />
      <path
        d="M22.4888 32.6182C22.4888 33.1175 22.1375 33.7509 21.1784 34.3144C20.2427 34.8641 18.8845 35.2363 17.3303 35.2363C15.7762 35.2363 14.4179 34.8641 13.4822 34.3144C12.5232 33.7509 12.1719 33.1175 12.1719 32.6182C12.1719 32.1188 12.5232 31.4854 13.4822 30.9219C14.4179 30.3722 15.7762 30 17.3303 30C18.8845 30 20.2427 30.3722 21.1784 30.9219C22.1375 31.4854 22.4888 32.1188 22.4888 32.6182Z"
        fill="white"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <path
        d="M-6 44.8828L18.3087 59.468L40.0244 45.8552"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      <path
        d="M62.1486 67.0435C62.0882 68.2892 70.7466 69.8358 72.0938 69.2547C74.1392 68.3723 76.1415 68.2587 77.619 66.7052C79.718 64.4982 80.936 61.624 81.584 58.7635C82.0374 56.7619 82.4021 54.7354 82.8109 52.7228C82.9706 51.9367 83.9732 54.1703 84.7551 54.7306C88.3423 57.3013 91.1529 59.4258 95.7879 59.4309C101.7 59.4375 104.23 53.7013 105.762 49.9219C106.197 48.8475 106.829 45.4148 106.598 46.5506C106.412 47.4647 106.652 47.6943 107.028 48.544C108.536 51.9599 114.295 55.2043 118.147 56.1808C124.411 57.7689 124.997 54.4669 128.824 51.4284"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M59.1486 59.8481C59.0882 61.0939 67.7466 62.6405 69.0938 62.0594C71.1392 61.177 73.1415 61.0634 74.619 59.5099C76.718 57.3029 77.936 54.4287 78.584 51.5682C79.0374 49.5666 79.4021 47.5401 79.8109 45.5275C79.9706 44.7414 80.9732 46.9749 81.7551 47.5352C85.3423 50.1059 88.1529 52.2304 92.7879 52.2356C98.7001 52.2421 101.23 46.5059 102.762 42.7266C103.197 41.6522 103.829 38.2195 103.598 39.3553C103.412 40.2694 103.652 40.499 104.028 41.3487C105.536 44.7646 111.295 48.009 115.147 48.9855C121.411 50.5736 121.997 47.2716 125.824 44.2331"
        stroke="#FFF46E"
        strokeWidth="3"
        strokeLinecap="round"
      />
      {/* ペン */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M313.172 122.484L314.915 124.461L313.055 126.101L312.564 126.077C311.892 126.044 311.299 125.418 311.295 124.735L311.292 124.142L313.172 122.484Z"
        fill="#161615"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314.75 117.539L320.021 123.518L315.119 126.161L311.536 122.096L314.75 117.539Z"
        fill="white"
      />
      <path
        d="M356.166 82.4216C357.406 81.3287 359.297 81.4477 360.39 82.6875C361.483 83.9274 361.364 85.8184 360.124 86.9114L320.173 122.13L316.215 117.64L356.166 82.4216Z"
        stroke="#FFF46E"
        strokeWidth="2"
      />
      {/* ここまで */}
    </svg>
  );
};
